/* buttons */
.button {
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 2.1rem;
  gap: 1rem;
  min-height: 4.4rem;
  text-decoration: none;
  text-align: center;
  border-radius: 13px;
  background: #289D3B;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border: 1px solid transparent;
  min-width: 180px;

  *[class*=icon-] {
    font-size: 1rem;
    //font-weight: 700;
  }

  &:hover,
  &:active,
  &:first-child:active,
  &.active {
    background: #fff;
    color: #289D3B;
    border-color: #289D3B;
    text-decoration: none;
  }

  &.button2 {
    background: #797979;

    &:hover,
    &:active,
    &:first-child:active,
    &.active {
      background: #fff;
      color: #797979;
      border-color: #797979;
    }
  }

  &.button3 {
    background: #48D0A4;

    &:hover,
    &:active,
    &:first-child:active,
    &.active {
      background: #fff;
      color: #48D0A4;
      border-color: #48D0A4;
    }
  }

  &.button4 {
    background: #fff;
    color: #5C5BA2;

    &:hover,
    &:active,
    &:first-child:active,
    &.active {
      background: #5C5BA2;
      color: #fff;
      border-color: #5C5BA2;
    }
  }

  &.button7 {
    background: #48D0A4;
    
    &:active,
    &:first-child:active,
    &:hover {
      background: #fff;
      border-color: #48D0A4;
      color: #48D0A4;
    }
  }

  &.button8 {
    background: #000;
    
    &:active,
    &:first-child:active,
    &:hover {
      border-color: #289D3B;
      background: #289D3B;
      color: #fff;
    }
  }

  &.bordered {
    color: #000;
    border-color: #289D3B;
    background: #fff;

    &:active,
    &:first-child:active,
    &:hover {
      border-color: #289D3B;
      background: #289D3B;
      color: #fff;
    }
  }


  @media only screen and (max-width: 480px) {
    font-size: 18px;
    font-weight: 600;
    min-width: 220px;
  }
}

.button-play {
  border-radius: 11px;
  background: #0F3A19;
  height: 59px;
  display: inline-flex;

  .txt {
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    border-radius: 11px;
    background: #289D3B;
    padding: 18px 33px;
    transition: all 0.9s ease;
    -webkit-transition: all 0.9s ease;
    position: relative;
    z-index: 99;
  }

  .icon {
    display: flex;
    align-items: center;
    padding: 18px 38px 18px 0;
    color: #fff;
    transition: all 0.9s ease;

    .icon-play {
      padding: 0 15px;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    text-decoration: none;

    .icon {
      padding-left: 0;
      padding-right: 0;
      margin-left: -87px;

      .icon-play {
        opacity: 0;
      }
    }


    .icon {
      color: #289D3B;

    }
  }

  &.grey-style {
    .txt {
      color: #000;
      background: #fff;
    }
  }

  @media only screen and (max-width: 768px) {
    .txt {
      font-size: 18px;
      padding: 18px 28px;
    }
  }
}

.rtl-page {
  .button-play .icon {
    padding: 18px 0 18px 38px;
  }

  .icon-play {
    transform: rotate(180deg);
  }
}


.text-link {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  // color: var(--seventh-color);
  *[class*=icon-] {
    font-size: 1rem;
  }

  &:hover {
    text-decoration: none;
    //color: var(--tertiary-color);
  }
}

.more {
  display: inline-flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.2rem;
  text-transform: capitalize;

  *[class*="icon-"] {
    font-size: 1rem;
    font-weight: 700;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-block {
  display: flex;
  width: 100%;
}

.centered-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

@media (max-width: 575px) {
  .btn-block-xs {
    width: 100%;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .btn-block-sm {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn-block-md {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btn-block-lg {
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .btn-block-xl {
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .btn-block-xxl {
    width: 100%;
  }
}