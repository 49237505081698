$site-domain: "";

$img-path: "#{$site-domain}/Media/Image";
/*add*/
.button {
  &.button5 {
    background: #303030;

    &:hover {
      background: #fff;
      color: #303030;
      border-color: #303030;
    }
  }

  &.button6 {
    background: #fff;
    color: #303030;

    &:hover {
      background: #303030;
      color: #fff;
      border-color: #fff;
    }
  }

  &.button9 {
    background: #138EEC;

    &:hover {
      background: #fff;
      color: #303030;
      border-color: #138EEC;
    }

    &.bordered {
      border-color: #138EEC;
      background: #fff;

      &:hover {
        background: #138EEC;
        color: #fff;
      }
    }
  }
}

.button-play {
  &.gradient {
    background: #5A5A5A;

    .txt {
      background: linear-gradient(94deg, #EE675B 1.92%, #D15C95 32.86%, #874ECB 71.78%, #714FD8 101.71%);
    }

    &:hover {
      .icon-play {
        &:before {
          background: linear-gradient(94deg, #EE675B 0%, #D15C95 32.86%, #874ECB 71.78%, #714FD8 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &.gradient2 {
    background: #5A5A5A;

    .txt {
      background: linear-gradient(104deg, #399E76 9.36%, #298668 40.72%, #466B88 110.51%);
    }

    &:hover {
      .icon-play {
        &:before {
          background: linear-gradient(104deg, #399E76 9.36%, #298668 40.72%, #466B88 110.51%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &.button-play2 {
    background: #5A5A5A;

    .txt {
      background: #48D0A4;
    }

    &:hover {
      .icon-play {
        color: #48D0A4;

      }
    }
  }

  &.button-play3 {
    background: #5A5A5A;

    .txt {
      background: #8A6FF9;
    }

    &:hover {
      .icon-play {
        color: #8A6FF9;

      }
    }
  }

  &.button-play4 {
    background: #5A5A5A;

    .txt {
      background: #138EEC;
    }

    &:hover {
      .icon-play {
        color: #138EEC;

      }
    }
  }
}

.rtl-page .button-play.gradient .txt,
.rtl-page .button-play.gradient:hover .icon-play:before {
  background: linear-gradient(-94deg, #EE675B 1.92%, #D15C95 32.86%, #874ECB 71.78%, #714FD8 101.71%);
}

.rtl-page .button-play.gradient2 .txt,
.rtl-page .button-play.gradient2:hover .icon-play:before {
  background: linear-gradient(-104deg, #399E76 -14.77%, #298668 22.87%, #466B88 106.65%);
}

.rtl-page .button-play.gradient:hover .icon-play:before,
.rtl-page .button-play.gradient2:hover .icon-play:before {
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*add end*/

/*updates*/
.main-holder {
  max-width: 1200px;
}

.section12.gradient {
  background: linear-gradient(180deg, #CBF3F3 8.47%, #DDFDFD 19.28%, #FFF 48.59%);
  // min-height: 940px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    right: 0;
    top: -185px;
    background: url(#{$img-path}/corner-section12-1.png) no-repeat 0 0 / 100% 100%;
    z-index: 3;
  }
}

.rtl-page .section12.gradient:before {
  right: auto;
  left: 0;
  transform: scaleX(-1);
}

@media only screen and (max-width: 768px) {
  .section12.gradient {
    &:before {
      width: 91px;
      height: 80px;
      top: -80px;
    }
  }
}

/*updates end*/

.section30 {
  position: relative;
  // background: #010407;
  padding: 170px 0 0;
  min-height: 934px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 934px;
    background: #010407;
  }

  &:after {
    content: "";
    position: absolute;
    width: 711px;
    height: 303px;
    top: 0;
    right: 50%;
    margin-right: -725px;
    background: url("#{$img-path}/decor-section30-1.png") no-repeat 0 0 / cover;
  }

  .txt-h {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    color: #fff;
    max-width: 700px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    .h1 {
      color: #fff;

      strong {
        background: linear-gradient(91deg, #F66A4B 19.65%, #D25C95 42.85%, #A34CBB 60.99%, #6750DF 79.13%);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        &::selection {
          background-clip: initial;
          -webkit-text-fill-color: initial;
        }
      }
    }

    p {
      max-width: 580px;
      margin-left: auto;
      margin-right: auto;
    }

    .btn-hold {
      padding-top: 20px;

      .button-play {
        background: #5A5A5A;
        .txt {
          padding: 18px;
        }

        .icon {
          padding: 18px 8px 18px 0;

          .icon-play {
            padding: 0 25px;
          }
        }
      }
    }
  }

  .img-h {
    margin-top: -67px;
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.rtl-page .section30:after {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -725px;
  transform: scaleX(-1);
}

.rtl-page .section30 .txt-h .h1 strong {
  background: linear-gradient(-91deg, #F66A4B 19.65%, #D25C95 42.85%, #A34CBB 60.99%, #6750DF 79.13%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rtl-page .section30 .text .btn-hold .button-play .icon {
  padding: 18px 0 18px 8px;
}

@media only screen and (max-width: 1500px) {
  .section30 {
    .img-h {
      margin-left: -150px;
      margin-right: -90px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section30 {
    padding-top: 60px;
    min-height: 700px;

    &:before {
      height: 700px;
    }

    .img-h {
      margin-top: 58px;
      margin: 60px -182px -115px;
    }
  }

}

@media only screen and (max-width: 479px) {
  .section30 {
    min-height: 870px;

    &:before {
      height: 870px;
    }

    .txt-h {
      p {
        letter-spacing: -.2px;
      }
    }
  }
}

.section31 {
  position: relative;
  padding: 155px 0;

  .row {
    align-items: center;
  }

  .txt-h {
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 75px;
    color: rgba(48, 48, 48, 0.75);

    p {
      max-width: 444px;
    }

    .btn-hold {
      padding-top: 30px;
    }
  }

  .img-h {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -15px;
    margin-bottom: -44px;
    margin-left: -70px;
    margin-right: -192px;

    picture {
      position: relative;

    }

    &:before {
      content: "";
      position: absolute;
      right: 100px;
      top: -140px;
      background: url("#{$img-path}/decor-section31-1.png") no-repeat 0 0 / cover;
      width: 514px;
      height: 609px;
    }
  }
}

// .rtl-page .section31 .img-h:before{
//     transform: scaleX(-1);
//     right: auto;
//     left: 100px;
// }
@media only screen and (max-width: 991px) {
  .section31 {
    .txt-h {
      p {
        max-width: none;
      }

      .btn-hold {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section31 {
    padding: 60px 0 65px;

    .txt-h {
      padding-bottom: 50px;
    }

    .img-h {
      margin-top: -47px;
      margin-bottom: -53px;
      margin-left: -60px;
      margin-right: -60px;

      &:before {
        content: none;
      }
    }

    .txt-h {
      font-size: 18px;

      .btn-hold {
        text-align: center;
        padding-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .section31 {
    .img-h {
      margin-left: -60px;
      margin-right: -350px;
    }
    .txt-h{
      .btn-hold{
        .button{
          min-width: 180px;
        }
      }
    }
  }

  .rtl-page .section31 .img-h {
    justify-content: flex-end;
  }
}

.section32 {
  position: relative;
  padding: 121px 0;
  background: linear-gradient(180deg, #E2E2E2 -20.09%, #FFF 54.21%);
  z-index: 1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    left: 0;
    z-index: 3;
  }

  &:before {
    top: -185px;
    background: url(#{$img-path}/corner-section32-1.png) no-repeat 0 0 / 100% 100%;
  }

  &:after {
    bottom: -185px;
    background: url(#{$img-path}/corner-section32-2.png) no-repeat 0 0 / 100% 100%;
  }


  .hold {
    position: relative;
    max-width: 1018px;
    margin: 0 auto;
    background: linear-gradient(109deg, #565656 33.22%, #2B2B2B 99.13%);
    border-radius: 10px;
    font-size: 20px;
    line-height: 32px;
    color: rgba(255, 255, 255, .75);
    text-align: center;

    &:before {
      content: "";
      position: absolute;
      left: 42px;
      right: 42px;
      top: -40px;
      bottom: -40px;
      background: #353535;
      border-radius: 10px;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

    }

    .inner {
      position: relative;
      z-index: 1;
      padding: 70px 25px 56px;

      &:before {
        position: absolute;
        font-family: 'icomoon';
        content: "\e944";
        color: #289D3B;
        font-size: 40px;
        top: 30px;
        right: 30px;
      }
    }

    .txt-h {
      max-width: 650px;
      margin: 0 auto 58px;
    }

    p {
      margin-bottom: 1rem;
    }

    h2 {
      color: #fff;
    }

    .timer {
      max-width: 575px;
      margin: 0 auto 45px;
    }

    .btn-hold {
      .button {
        min-width: 248px;
      }
    }
  }
}

.rtl-page .section32:before,
.rtl-page .section32:after {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}

.rtl-page .section32 .hold {
  background: linear-gradient(109deg, #565656 33.22%, #2B2B2B 99.13%);
  background: linear-gradient(199deg, #565656 33.22%, #2B2B2B 99.13%);
}

.rtl-page .section32 .hold .inner:before {
  right: auto;
  left: 30px;
}

@media only screen and (max-width: 768px) {
  .section32 {
    padding: 80px 0;

    &:before,
    &:after {
      width: 91px;
      height: 80px;
    }

    &:before {
      top: -80px;
    }

    &:after {
      bottom: -80px;
    }

    .hold {
      font-size: 18px;

      &:before {
        left: 13px;
        right: 13px;
        top: -20px;
        bottom: -20px;
      }

      .inner {
        padding: 50px 25px 50px;

        &:before {
          font-size: 30px;
          top: 12px;
          right: 12px;
        }
      }

      h2 {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 2.1rem;
      }

      .txt-h {
        margin: 0 auto 24px;
      }

      .timer {
        margin: 0 auto 50px;
      }
    }
  }

  .rtl-page .section32 .hold .inner:before {
    right: auto;
    left: 12px;
  }
}

.section33 {
  position: relative;
  padding: 124px 0;
  background: linear-gradient(34deg, #F8F8F8 16.25%, #F3F3F3 79.99%);

  &:before {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    right: 0;
    top: -185px;
    background: url(#{$img-path}/corner-section33-1.png) no-repeat 0 0 / 100% 100%;
    z-index: 3;
  }

  .row {
    align-items: center;
  }

  .txt-h {
    position: relative;
    z-index: 1;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 0;
    max-width: 460px;
    color: rgba(48, 48, 48, 0.75);

    .btn-hold {
      padding-top: 30px;

      .button {
        min-width: 190px;
      }
    }
  }

  .img-h {
    display: flex;
    justify-content: center;
    margin-right: -45px;
    margin-left: 45px;
    margin-bottom: -25px;

    picture {
      position: relative;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url("#{$img-path}/decor-section33-1.png") no-repeat 50% 0 / cover;
    }
  }
}

.rtl-page .section33:before {
  right: auto;
  left: 0;
  transform: scaleX(-1);
}

.rtl-page .section33 .img-h {
  margin-right: 45px;
  margin-left: -45px;

  :before {
    transform: scaleX(-1);
  }
}

@media only screen and (max-width: 991px) {
  .section33 {

    .txt-h {
      max-width: none;
      padding-bottom: 50px;
    }

    .img-h {
      margin-left: -60px;
      margin-right: -60px;
    }
  }

  .rtl-page .section33 .img-h {
    margin-left: -60px;
    margin-right: -60px;
  }
}

@media only screen and (max-width: 768px) {
  .section33 {
    padding: 60px 0;

    &:before {
      width: 91px;
      height: 80px;
      top: -80px;
    }

    .txt-h {
      font-size: 18px;

      .btn-hold {
        padding-top: 20px;

        .button {
          font-size: 18px;
          min-width: 22rem;
        }
      }
    }

    .img-h {
      position: relative;
      margin-bottom: -15px;

      &:before {
        top: 80px;
        bottom: 80px;
        left: 45px;
        right: 45px;
        background-image: url("#{$img-path}/decor-section33-1-sm.png");
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .section33{
    .txt-h{
      padding-bottom: 110px;
    }
    .img-h {
      margin-left: -25px;
      margin-right: -35px;
      &:before{
        left: 15px;
        right: 15px;
        top: -120px;
        bottom: 190px;
      }
    }
  }
}

.section34 {
  padding: 150px 0;
  position: relative;


  .items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 30px 0 50px;

    &:before {
      content: "";
      position: absolute;
      top: 117px;
      left: 477px;
      left: 50%;
      margin-left: -110px;
      width: 446px;
      height: 424px;
      background: url("#{$img-path}/decor-section34-1.png") no-repeat 50% 0 / cover;
    }

    &:after {
      content: "";
      position: absolute;
      left: -380px;
      bottom: -320px;
      width: 796px;
      height: 746px;
      background: url("#{$img-path}/decor-section34-2.png") no-repeat 50% 0 / cover;
    }

    .item {
      position: relative;
      z-index: 1;
      max-width: 370px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 34px;
      border-radius: 35px;
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 5.662px 14.154px 0px rgba(0, 0, 0, 0.13);
      padding: 20px 20px 30px;

      font-size: 25px;
      font-weight: 500;
      line-height: 40px;

      *[class*=icon-] {
        font-size: 50px;
        color: #66B066;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bottom-text {
    font-size: 26px;
    line-height: 40px;
  }

  .btn-hold {
    padding-top: 30px;

    .button {
      min-width: 201px;
      font-size: 18px;
    }
  }
}

.rtl-page .section34 .items:before {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: -110px;
}

.rtl-page .section34 .items:after {
  left: auto;
  right: -380px;
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 768px) {
  .section34 {
    padding: 60px 0;

    .items {
      padding: 0 0 40px;
      margin-top: -5px;
      gap: 10px;

      &:before {
        top: 345px;
        margin-left: -160px;
      }

      .item {
        max-width: none;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        gap: 16px;
        font-size: 20px;
        line-height: 32px;
      }
    }

    .btn-hold {
      padding-top: 20px;
    }
  }

  .rtl-page .section34 .items:before {
    margin-left: 0;
    margin-right: -285px;
  }
}
@media only screen and (max-width: 480px) {
  .section34{
    h2{
      font-size: 28px;
      line-height: 38px;
    }
  }
}

.section35 {
  padding-top: 91px;

  .hold {
    position: relative;
    color: var(--white-color);
    padding: 120px 15px 230px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 35px;
      opacity: 0.8;
      background: linear-gradient(211deg, #4ABC91 18.85%, #4AC0F2 105.91%);
    }

    .txt-h {
      position: relative;
      max-width: 580px;
      margin: 0 auto;

      h2 {
        color: var(--white-color);
      }

      .btn-hold {
        padding-top: 25px;
        padding-bottom: 70px;

        +.addition-txt{
          margin-top: -40px;
        }

        .button {
          min-width: 190px;
        }
      }
    }
  }
  &.bg_gradient {
   .hold:before {
     background: var(--gradient, linear-gradient(91deg, #F66A4B 19.65%, #D25C95 42.85%, #A34CBB 60.99%, #6750DF 79.13%));
    }
  }
  &.bg_gradient_darken {
    .hold:before {
      opacity: 0.8;
      background: linear-gradient(231deg, #4ABC91 18.37%, #4ABFD8 48.95%, #096C96 68.03%, #02202D 85.33%)
    }

  }

  .img-h {
    margin-top: -255px;
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.rtl-page .section35 .hold:before {
  transform: scaleX(-1);
}

@media only screen and (max-width: 768px) {
  .section35 {
    padding-top: 20px;

    .hold {
      margin: 0 -5px;
      padding: 40px 15px 275px;
      &:before{
        bottom: 50px;
        bottom: 10px;
      }
      .txt-h {
        .btn-hold {
          padding-top: 10px;
          padding-bottom: 50px;

          +.addition-txt{
            margin-top: -25px;
            padding-bottom: 0;
          }

          .button{
            min-width: 180px;
          }
        }
        .addition-txt{
          font-size: 1.6rem;
          line-height: 3.2rem;
        }
      }
    }

    picture {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .img-h {
      margin: -300px 0 0;
    }

  }
}

.section36 {
  position: relative;
  padding: 170px 0 35px;
  min-height: 614px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 614px;
    background: #010407;
  }

  &:after {
    content: "";
    position: absolute;
    width: 711px;
    height: 303px;
    top: 0;
    right: 50%;
    margin-right: -725px;
    background: url("#{$img-path}/decor-section36-1.png") no-repeat 0 0 / cover;
  }

  .txt-h {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    color: var(--white-color);
    max-width: 960px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    .h1 {
      color: var(--white-color);

      > span {
        color: #4ABC91;
      }
    }


    .btn-hold {
      padding-top: 20px;

      .button-play {
        background: #5A5A5A;
        .txt {
          padding: 18px 28px;
        }

        .icon {
          padding: 18px 8px 18px 0;

          .icon-play {
            padding: 0 25px;
          }
        }
      }
    }
  }

  .img-h {
    margin-top: 5px;
    position: relative;
    display: flex;
    justify-content: center;

    > picture {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 796px;
        height: 728px;
        bottom: -50px;
        left: 50%;
        margin-left: -950px;
        background: url("#{$img-path}/decor-section36-2.png") no-repeat 0 0 / cover;
      }

      img {
        position: relative;
      }
    }
  }
}

.rtl-page .section36:after {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -725px;
  transform: scaleX(-1);
}

.rtl-page .section36 .text .btn-hold .button-play .icon {
  padding: 18px 0 18px 8px;
}

@media only screen and (max-width: 768px) {
  .section36 {
    padding-top: 60px;
    padding-bottom: 60px;

    .txt-h {
      .h1 {
        br {
          display: none;
        }
      }

      .btn-hold {
        padding-top: 25px;
      }
    }

    .img-h {
      margin-top: 58px;
      margin: 50px -27px 0px;

      > picture {
        position: relative;

        &:before {
          content: none;
        }
      }
    }
  }

}

@media only screen and (max-width: 479px) {
  .section36 {
    min-height: 870px;

    &:before {
      height: 870px;
    }

    .txt-h {
      p {
        letter-spacing: -.2px;
      }
    }
  }
}


.section37 {
  position: relative;
  padding: 95px 0 105px;

  &:after{
    content: "";
    position: absolute;
    right: 0;
    bottom: -185px;
    width: 211px;
    height: 185px;
    z-index: 3;
    background: url(#{$img-path}/corner-section37-1.png) no-repeat 0 0 / 100% 100%;
  }

  .row {
    align-items: center;
  }

  .txt-h {
    max-width: 320px;
    font-size: 20px;
    line-height: 32px;
    padding-top: 10px;

    .btn-hold {
      padding-top: 30px;
    }
  }

  .columns {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 9px 0 -170px;

    &:before {
      content: "";
      position: absolute;
      right: -65px;
      top: -35px;
      background: url("#{$img-path}/decor-section37-1.png") no-repeat 0 0 / cover;
      width: 514px;
      height: 609px;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .column:nth-child(1) {
      order: 2;
    }

    .column:nth-child(2) {
      order: 1;
    }

    .column:nth-child(3) {
      order: 3;
    }

    .column:nth-child(1) {
      width: 100%;
      max-width: 306px;
      flex: 1 1 auto;
      align-items: center;

      .item {
        max-width: 226px;
        margin-left: 15px;
      }
    }

    .column:nth-child(2) {
      padding-top: 80px;
      flex: 1;

      .item:nth-child(1) {
        margin-right: 6px;
      }

      .item:nth-child(2) {
        margin-right: -5px;
      }
    }

    .column:nth-child(3) {
      //   padding-top: 225px;
      justify-content: flex-end;
      flex: 1;
    }

    h3 {
      font-size: 40px;
      color: #4ABC91;
      font-weight: 600;
      line-height: 52px;
      margin-bottom: -20px;
      margin-top: 5px;
    }

    .item {
      position: relative;
      border-radius: 8px;
      background: var(--white-color);
      box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05), 0px 14px 64px -4px rgba(35, 21, 91, 0.05);
      padding: 18px 25px;
      padding-inline-end: 22px;
      font-size: 16px;
      line-height: 24px;

      h4{
        display: none;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #303030;
        margin-bottom: 1rem;
        strong{
          color: #48D0A4;
          font-size: 26px;
          font-weight: 700;
          line-height: 24px;
        }
      }
      
      >*:last-child{margin-bottom: 0;}
    }
  }

  .img-h {
    position: relative;
    margin: 0 -21px 40px -11px;
    display: flex;
    justify-content: center;

    picture {
      position: relative;
      z-index: 1;

      *[class*=caption] {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        color: var(--white-color);
        font-weight: 600;

        strong{
          display: none;
          font-weight: 600;
        }
      }

      .caption01 {
        font-size: 26px;
        top: 95px;
      }

      .caption02 {
        font-size: 25px;
        top: 165px;
      }

      .caption03 {
        font-size: 23px;
        top: 230px;
      }

      .caption04 {
        font-size: 18px;
        top: 290px;
      }
    }
  }
}

.rtl-page .section37:after{
  right: auto;
  left: 0;
  transform: scaleX(-1);
}
.rtl-page.section37 .columns .column:nth-child(1) .item {
  margin-left: 0;
  margin-right: 15px;
}

.rtl-page .section37 .columns .column:nth-child(2) .item:nth-child(1) {
  margin-right: 0;
  margin-left: 6px;
}

.rtl-page .section37 .columns .column:nth-child(2) .item:nth-child(2) {
  margin-right: 0;
  margin-left: -5px;
}

.rtl-page .section37 .img-h {
  margin: 0 -11px 40px -21px;
}

.rtl-page .section37 .img-h picture img {
  transform: scaleX(-1);
}

@media only screen and (max-width: 1200px) {
  .section37 {
    .txt-h {
      max-width: none;
      padding-top: 0;
      padding-bottom: 50px;
    }

    .columns {
      max-width: 731px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section37 {
    padding: 0 0 80px;

    &:after {
      width: 91px;
      height: 80px;
      bottom: -80px;
    }

    .txt-h {
      .btn-hold {
        padding-top: 20px;

        .button {
          min-width: 180px;
          font-size: 16px;
        }
      }
    }

    .columns {
      flex-direction: column;
      gap: 20px;

      &:before {
        content: none;
      }

      .column {
        order: unset !important;
      }

      .item{
        padding: 18px 15px;
        padding-inline-start: 35px;
        h4{
          display: flex;
          strong{
            width: 20px;
            margin-inline-start: -20px;
          }
        }
      }
      .column:nth-child(1) {
        max-width: none;

        .item {
          margin: 0;
        }
      }

      .column:nth-child(2) {
        padding-top: 0;

        .item:nth-child(1),
        .item:nth-child(2) {
          margin-right: 0;
        }
      }

      h3 {
        margin-top: 0;
      }
    }

    .img-h {
      margin: 0;
      picture{
        *[class*=caption] {
          strong{
            display: inline;
          }
          &.caption04{
            top: 300px;
            line-height: 20px;
            strong{display: block;}
          }
        }
      }
    }
  }

  .rtl-page .section37 .img-h {
    margin: 0;
  }

  .rtl-page .section37 .columns .column:nth-child(1) .item {
    margin: 0;
  }

  .section37 .columns .column:nth-child(2) .item:nth-child(1),
  .section37 .columns .column:nth-child(2) .item:nth-child(2) {
    margin-right: 0;
    margin-left: 0;
  }
}

.section38 {
  position: relative;
  padding: 110px 0 100px;
  background: linear-gradient(180deg, #E2E2E2 -20.09%, #FFF 54.21%);
  z-index: 1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    left: 0;
    z-index: 3;
  }

  &:before {
    top: -185px;
    background: url(#{$img-path}/corner-section38-1.png) no-repeat 0 0 / 100% 100%;
  }

  &:after {
    bottom: -185px;
    background: url(#{$img-path}/corner-section38-2.png) no-repeat 0 0 / 100% 100%;
  }

  .items {
    padding: 20px 0;
    gap: 30px 0;

    .item {
      border-radius: 20px;
      border: 1px solid #D9D9D9;
      padding: 20px 20px 48px;
      min-height: 100%;
      position: relative;
      font-size: 18px;
      line-height: 28px;

      h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      > *:last-child {
        margin-bottom: 0;
      }

      &:after {
        content: "";
        position: absolute;
        right: 20px;
        bottom: 15px;
        width: 10px;
        height: 10px;
        background: #48D0A4;
        border-radius: 50%;
      }
    }

    .img-h {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -14px;
      margin-bottom: -46px;
    }
  }

  .btn-hold {
    padding-top: 76px;

    .button {
      min-width: 190px;
    }
  }

}

.rtl-page .section38:before,
.rtl-page .section38:after {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}

.rtl-page .section38 .items .item:after {
  right: auto;
  left: 20px;
}

@media only screen and (max-width: 768px) {
  .section38 {
    padding: 60px 0 80px;

    &:before,
    &:after {
      width: 91px;
      height: 80px;
    }

    &:before {
      top: -80px;
    }

    &:after {
      bottom: -80px;
    }

    .items {
      gap: 20px 0;
      padding: 5px 0;

      .item {
        padding-bottom: 38px;

        &:after {
          bottom: 20px;
        }

      }

      > *:nth-child(4) .item,
      > *:nth-child(6) .item {
        padding-bottom: 48px;

        &:after {
          bottom: 30px;
        }
      }

      .img-h {
        margin: 0;
      }
    }

    .btn-hold {
      padding-top: 35px;

      .button {
        min-width: 190px;
        font-size: 16px;
      }
    }
  }
}

.section39 {
  position: relative;
  background: linear-gradient(-90deg, #48D0A4 18.93%, #9CE5CB 78.47%);

  &:before {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    right: 0;
    top: -185px;
    background: url(#{$img-path}/corner-section39-1.png) no-repeat 0 0 / 100% 100%;
    z-index: 3;
  }

  .section-inner {
    position: relative;
    overflow: hidden;
    padding: 165px 0 132px;

    &:before {
      content: "";
      position: absolute;
      right: 50%;
      margin-right: -670px;
      width: 788px;
      height: 1006px;
      bottom: 0;
      background: url("#{$img-path}/decor-section39-1.png") no-repeat 50% 0 / 100% 100%;
      opacity: .3;
    }
  }

  .row {
    align-items: center;
  }

  .txt-h {
    position: relative;
    z-index: 1;
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 30px;
    max-width: 460px;

    .btn-hold {
      padding-top: 30px;

      .button {
        min-width: 190px;
      }
    }
  }

  .img-h {
    display: flex;
    justify-content: center;
    margin-left: 42px;
    margin-right: -12px;
    margin-top: -3px;
    margin-bottom: -20px;

    picture {
      position: relative;
    }
  }
}

.rtl-page .section39 {
  background: linear-gradient(90deg, #48D0A4 18.93%, #9CE5CB 78.47%);
}

.rtl-page .section39:before {
  right: auto;
  left: 0;
  transform: scaleX(-1);
}

@media only screen and (max-width: 1200px) {
  .section39 {
    .section-inner {
      &:before {
        right: 50%;
        margin-right: -540px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .section39 {

    .txt-h {
      max-width: none;
      padding-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .section39 {

    background: linear-gradient(90deg, #9CE5CB 18.93%, #48d0a4 78.47%);

    &:before {
      width: 91px;
      height: 80px;
      top: -80px;
      // background-image: url(#{$img-path}/corner-section39-1-sm.png);
    }

    .section-inner {
      padding: 60px 0;

      &:before {
        width: 600px;
        height: 555px;
        left: 50%;
        margin-left: -270px;
        background-image: url("#{$img-path}/decor-section39-1-sm.png");
      }
    }

    .txt-h {

      .btn-hold {
        padding-top: 20px;

        .button {
          font-size: 18px;
        }
      }
    }

    .img-h {
      margin: 20px -71px -20px;
      margin: 53px -21px -33px;
    }
  }

  .rtl-page .section39 {
    background: linear-gradient(-90deg, #9CE5CB 18.93%, #48d0a4 78.47%);
  }
}

.section40 {
  padding: 150px 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 772px;
    height: 760px;
    bottom: 0;
    right: 50%;
    margin-right: -925px;
    background: url("#{$img-path}/decor-section40-1.png") no-repeat 0 0/cover;
  }


  h2 {
    br {
      display: none;
    }
  }

  .items {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 70px 0 65px;
    position: relative;


    .item {
      // max-width: calc(25% - 20px);
      font-size: 18px;
      line-height: 28px;
      color: rgba(48, 48, 48, 0.75);

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 13px;

        li {
          display: flex;
          position: relative;
          padding-left: 40px;

          &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 40px;
            height: 40px;
            border: 1px solid #D9D9D9;
            border-radius: 50%;
          }

          > span {
            display: inline-flex;
            padding: 5px 22px;
            border: 1px solid #D9D9D9;
            border-radius: 20px;

            &:before,
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              background: #D9D9D9;
              margin: 11px 0 0 11px;
            }

            &:before {
              left: 0;
              width: 16px;
              height: 2px;
              top: 7px;
            }

            &:after {
              height: 16px;
              width: 2px;
              left: 7px;
            }
          }

        }
      }
    }

    .slick-slide > div {
      display: flex;
      height: 100%;
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
    }
  }

  .slick-control {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 20px;
  }

  .slick-arrow {
    position: static;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    top: 7.2rem;
    z-index: 1;
    padding: 0;
    margin: 0;
    font-size: 0;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    border: 0;
    text-align: center;
    background: transparent;
    color: #5F6368;

    &:before {
      font-size: 2.5rem;
      line-height: 4rem;
      display: inline-block;
      font-family: 'icomoon';
      content: "\e925";
      color: #5F6368;
      opacity: 1;
    }

    &:hover {
      background: #48D0A4;
      color: var(--white-color);

      &:before {
        color: var(--white-color);
      }
    }

    &.slick-prev {
      left: 1rem;

      &:before {
        transform: rotate(0deg);
      }
    }

    &.slick-next {
      right: 1rem;

      &:before {
        transform: rotate(-180deg);
      }
    }
  }

  .btn-hold {
    padding-top: 30px;

    .button {
      min-width: 180px;
    }
  }

  .bottom-text {
    font-size: 26px;
    line-height: 40px;
  }
}

.rtl-page .section40:before {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -925px;
  transform: scaleX(-1);
}

.rtl-page .section40 .items .item ul li {
  padding-left: 0;
  padding-right: 40px;
}

.rtl-page .section40 .items .item ul li:before {
  left: auto;
  right: 0;
}

.rtl-page .section40 .items .item ul li > span:before {
  left: auto;
  right: 0;
  margin: 11px 11px 0 0;
}

.rtl-page .section40 .items .item ul li > span:after {
  left: auto;
  right: 7px;
  margin: 11px 11px 0 0;
}

.rtl-page .section40 .slick-arrow.slick-prev:before {
  transform: rotate(180deg);
}

.rtl-page .section40 .slick-arrow.slick-next:before {
  transform: rotate(0deg);
}

@media only screen and (max-width: 1200px) {
  .section40 {
    h2 {
      margin-bottom: 40px;
    }

    .items {
      display: block;
      margin: 0 -10px;
      padding-top: 40px;
      padding-bottom: 55px;

      .item {
        margin: 0 10px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section40 {
    padding: 60px 0;

    h2 {
      br {
        display: inline;
      }
    }

    .btn-hold {
      padding-top: 20px;

      .button {
        min-width: 180px;
        font-size: 16px;
      }
    }
  }
}

.section41 {
  padding: 137px 0;

  .hold {
    position: relative;
    color: var(--white-color);
    padding: 70px 0;
    min-height: 632px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 35px;
      opacity: 0.8;
      background: linear-gradient(211deg, #4ABC91 18.85%, #4AC0F2 105.91%);
    }

    .row {
      align-items: center;
    }

    .txt-h {
      position: relative;
      padding: 10px 10px 0 100px;

      h2 {
        color: var(--white-color);
      }

      .btn-hold {
        padding-top: 25px;

        .button {
          min-width: 200px;
          font-size: 18px;
        }
      }
    }

    .img-h {
      margin-right: -26px;
      margin-left: -28px;
      margin-top: -170px;
      margin-bottom: -110px;
      position: relative;
      display: flex;
      justify-content: center;
    }
  }
}

.rtl-page .section41 .hold .txt-h {
  padding: 10px 100px 0 10px;
}

.rtl-page .section41 .hold .img-h {
  margin-right: -28px;
  margin-left: -26px;
}

.rtl-page .section41 .hold .img-h img {
  transform: scaleX(-1);
}

.rtl-page .section41 .hold:before {
  transform: scaleX(-1);
}

@media only screen and (max-width: 991px) {

  .section41 {
    .hold {
      .txt-h {
        padding: 0 15px 48px;
      }

      .img-h {
        margin: 0 -10px -155px -37px;
      }
    }
  }

  .rtl-page .section41 .hold .txt-h {
    padding: 0 0 48px;
  }

  .rtl-page .section41 .hold .img-h {
    margin: 0 -37px -155px -10px;
  }
}

@media only screen and (max-width: 768px) {
  .section41 {
    padding-top: 60px;
    padding-bottom: 150px;

    .hold {
      padding: 70px 0;
      margin: 0 -5px;
      min-height: 550px;

      .txt-h {
        padding: 0 15px 48px;

        .btn-hold {
          padding-top: 10px;
        }
      }

      .img-h {
        margin: 0 -10px -155px -37px;
      }
    }
  }

  .rtl-page .section41 .hold .txt-h {
    padding: 0 0 48px;
  }

  .rtl-page .section41 .hold .img-h {
    margin: 0 -37px -155px -10px;
  }
}


.section42 {
  padding: 90px 0 45px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 711px;
    height: 303px;
    top: 0;
    right: 50%;
    margin-right: -725px;
    background: url("#{$img-path}/decor-section42-1.png") no-repeat 0 0/cover;
  }

  &:after {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    right: 0;
    bottom: -185px;
    background: url(#{$img-path}/corner-section42-1.png) no-repeat 0 0 / 100% 100%;
    z-index: 3;
  }

  .hold {
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    color: var(--white-color);
    padding: 67px 111px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #131a46 url("#{$img-path}/section42-hold.jpg") no-repeat 50% / cover;
      border-radius: 99px;
    }

    .inner {
      position: relative;
      padding: 50px;
      padding-right: 265px;
      min-height: 333px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 34px;
        backdrop-filter: blur(19.027973175048828px);
      }

      &:before {
        background: linear-gradient(158deg, rgba(101, 31, 255, 0.50) 9.95%, rgba(0, 188, 212, 0.50) 92.81%);
      }

      &:after {
        background: linear-gradient(121deg, rgba(196, 226, 203, 0.40) -2.02%, rgba(95, 180, 111, 0.40) 25.08%, rgba(74, 181, 132, 0.70) 91.42%);
      }

      > *:not(.text-center) {
        position: relative;
        z-index: 1;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 1;
      }

      h1 {
        position: relative;
        z-index: 1;
        color: var(--white-color);
      }
    }
  }

  .img-h {
    margin: -359px -67px -75px 0;
    position: relative;
    display: flex;
    justify-content: flex-end;

    picture {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        right: -40px;
        bottom: -75px;
        background: url("#{$img-path}/decor-section42-3.png") no-repeat 0 0 / cover;
        width: 329px;
        height: 389px;
      }
    }
  }

  .txt-h {
    position: relative;
    padding: 100px 0 0;
    color: rgba(48, 48, 48, 0.75);
    font-size: 20px;
    line-height: 32px;

    
    strong {
      font-weight: 600;
    }

    h2 {
      position: relative;
      padding: 0 72px;
      margin-top: -14px;
      display: inline-block;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 62px;
        bottom: -12px;
        width: 48px;
        margin: 0 auto;
        height: 6px;
        background: url("#{$img-path}/decor-section42-text.png") no-repeat 50% / cover;
      }

      &:before {
        content: attr(data-text);

        position: absolute;
        left: 0;
        top: -86px;

        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(168, 211, 223, 0.50) 0%, rgba(245, 172, 245, 0.50) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      span {
        position: relative;
      }

    }
  }

}

.rtl-page .section42:before {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -725px;
  transform: scaleX(-1);
}

.rtl-page .section42:after {
  right: auto;
  left: 0;
  transform: scaleX(-1);
}

.rtl-page .section42 .hold:before,
.rtl-page .section42 .hold .inner:before,
.rtl-page .section42 .hold .inner:after {
  transform: scaleX(-1);
}

.rtl-page .section42 .hold .inner {
  padding: 50px;
  padding-left: 265px;
}

.rtl-page .section42 .hold .inner img {
  right: auto;
  left: 30px;
}

.rtl-page .section42 .txt-h h2:before {
  left: auto;
  right: 0;
}

.rtl-page .section42 .txt-h h2:after {
  left: 62px;
  right: 0;
}

.rtl-page .section42 .img-h {
  margin: -359px 0 -75px -160px;
}

@media only screen and (max-width: 991px) {
  .section42 {

    .hold {
      .inner {
        padding: 50px;
        padding-bottom: 129px;

        img {
          display: inline-block;
          vertical-align: top;
          position: relative;
          right: 0;
          left: 0;
          top: 0;
          margin-bottom: 30px;
        }
      }
    }

    .img-h {
      justify-content: center;
      margin: -238px -78px -41px -220px;

      picture {
        position: relative;

        &:before {
          right: -60px;
          bottom: -125px;
        }
      }
    }

    .txt-h {
      padding-left: 80px;
      padding-right: 80px;

      h2 {
        padding: 0;
        margin-bottom: 40px;

        &:before {
          left: -50px;
        }

        &:after {
          left: 0;
          right: 0;
        }
      }
    }
  }

  .rtl-page .section42 .hold .inner {
    padding: 50px;
    padding-bottom: 129px;
  }

  .rtl-page .section42 .hold .inner img {
    left: 0;
    right: 0;
  }

  .rtl-page .section42 .img-h {
    margin: -208px -78px -41px -220px;
  }

  .rtl-page .section42 .txt-h h2:before {
    left: auto;
    right: -50px;
  }

  .rtl-page .section42 .txt-h h2:after {
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .section42 {
    padding-top: 57px;
    padding-bottom: 60px;

    &:before {
      width: 468px;
      height: 243px;
      width: 534px;
      height: 333px;
      margin-right: -425px;
    }

    &:after {
      width: 91px;
      height: 80px;
      bottom: -80px;
    }

    .hold {
      margin: 0 -15px;
      padding: 33px 20px;

      &:before {
        border-radius: 44px;
        background: #131a46 url("#{$img-path}/section42-hold-sm.jpg") no-repeat 0 0 / cover;
      }

      .inner {
        padding: 30px 28px 129px;


        .h1 {
          font-size: 30px;
          line-height: 42px;
        }
      }
    }

    .txt-h {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.8rem;
      line-height: 3.2rem;
    }

  }

  .rtl-page .section42:before {
    margin-right: 0;
    margin-left: -425px;
  }

  .rtl-page .section42 .hold .inner {
    padding: 30px 28px 129px;
  }

}

.section43 {
  position: relative;
  padding: 245px 0 120px;
  background: linear-gradient(180deg, #E2E2E2 -20.09%, #FFF 54.21%);

  z-index: 1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    left: 0;
    z-index: 3;
  }

  &:before {
    top: -185px;
    background: url(#{$img-path}/corner-section43-1.png) no-repeat 0 0 / 100% 100%;
  }

  &:after {
    bottom: -185px;
    background: url(#{$img-path}/corner-section43-2.png) no-repeat 0 0 / 100% 100%;
  }

  .map {
    position: absolute;
    left: 0;
    top: 0;

    picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .txt-h {
    position: relative;
    max-width: 940px;
    margin: 0 auto;
    color: rgba(48, 48, 48, 0.75);
    font-size: 20px;
    line-height: 32px;

    h2 {
      display: inline-block;
      position: relative;
      padding: 0 0 0 55px;
      margin-bottom: 40px;

      &:before {
        content: attr(data-text);
        position: absolute;
        left: 10px;
        top: -86px;
        font-size: 112px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        color: #E3E3E3;
        text-shadow: 0px 4px 46.3px rgba(0, 0, 0, 0.10);
      }

      > span {
        position: relative;
      }
    }

    address {
      margin-bottom: 37px;
    }

    address strong,
    p strong {
      font-weight: 600;
    }

    *[class*=icon] {
      display: inline-block;
      vertical-align: top;
      color: #4ABC91;
      font-size: 62px;
      margin-bottom: 36px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

}

.rtl-page .section43:before,
.rtl-page .section43:after {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}

.rtl-page .section43 .txt-h h2 {
  padding: 0 55px 0 0;
}

.rtl-page .section43 .txt-h h2:before {
  left: auto;
  right: 10px;
}

@media only screen and (max-width: 1600px) {
  .section43 {
    padding-top: 345px;
  }

}

@media only screen and (max-width: 768px) {
  .section43 {
    padding: 295px 0 110px;

    &:before,
    &:after {
      width: 91px;
      height: 80px;
    }

    &:before {
      top: -80px;
    }

    &:after {
      bottom: -80px;
    }

    .map {
      left: -192px;
      right: -176px;
    }

    .txt-h {
      font-size: 18px;
      line-height: 32px;

      h2 {
        padding-left: 0;
        margin-bottom: 30px;

        &:before {
          font-size: 60px;
          line-height: normal;
          left: 0;
          top: -46px;
        }
      }

      address {
        margin-bottom: 20px;
      }

      *[class*=icon] {
        margin-bottom: 20px;
      }
    }
  }

  .rtl-page .section43 .txt-h h2 {
    padding: 0;
  }

  .rtl-page .section43 .txt-h h2:before {
    left: auto;
    right: 0;
  }
}


.section44 {
  padding: 112px 0 70px;
  position: relative;
  background: linear-gradient(-90deg, #48D0A4 18.93%, #9CE5CB 78.47%);

  &:before {
    content: "";
    position: absolute;
    width: 211px;
    height: 185px;
    right: 0;
    top: -185px;
    background: url(#{$img-path}/corner-section44-1.png) no-repeat 0 0 / 100% 100%;
    z-index: 3;
  }

  h2 {
    position: relative;
    color: var(--white-color);
    margin-bottom: 95px;

    &:before {
      content: attr(data-text);
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: -86px;
      font-size: 112px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      color: #59C9A5;
      text-shadow: 0px 4px 46.3px rgba(0, 0, 0, 0.10);
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -28px;
      width: 44px;
      height: 3px;
      background: var(--white-color);
      margin: 0 auto;
    }

    > span {
      position: relative;
    }
  }

  .items {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0 70px;
    overflow: hidden;

    .item {
      position: relative;
      padding-bottom: 58px;
      display: flex;
      gap: 20px;
      color: var(--white-color);
      align-items: flex-end;
      width: 100%;
      max-width: 470px;


      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 4;
      }

      &:nth-child(2) {
        margin-left: 160px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 60px;
        bottom: 0;
        width: 1px;
        height: 58px;
        background: var(--white-color);
        margin: 0 auto;
      }

      &:after {
        content: "";
        position: absolute;
        background: var(--white-color);
        bottom: 0;
        left: -100vw;
        right: -100vw;
        height: 1px;
      }

      &:nth-child(2n) {
        padding-top: 58px;
        padding-bottom: 0;
        align-items: flex-start;

        &:before {
          bottom: auto;
          top: 0;
        }

        &:after {
          bottom: auto;
          top: -1px;
        }

        .txt-h {
          padding-top: 5px;
        }
      }

      .img-h {
        width: 120px;
        height: 120px;
        position: relative;
        background: var(--white-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .txt-h {
        flex: 1 1 120px;

        > div {
          display: flex;
          gap: 48px;
        }

        .number {
          display: block;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 10px;
        }

        .year {
          display: block;
          color: #59C9A5;
          font-size: 100px;
          font-style: normal;
          font-weight: 700;
          line-height: 90px;
        }

        .caption {
          display: block;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin-top: -40px;

        }

        *[class*=icon-] {
          display: inline-block;
          vertical-align: top;
          font-size: 52px;
        }
      }
    }
  }
}

.rtl-page .section44 {
  background: linear-gradient(90deg, #48D0A4 18.93%, #9CE5CB 78.47%);
}

.rtl-page .section44:before {
  right: auto;
  left: 0;
  transform: scaleX(-1);
}

.rtl-page .section44 .items .item:nth-child(2) {
  margin-left: 0;
  margin-right: 160px;
}

.rtl-page .section44 .items .item:before {
  left: auto;
  right: 60px;
}


@media only screen and (max-width: 1200px) {
  .section44 {
    .items {
      .item {
        max-width: calc(50% - 45px);

        &:nth-child(2) {
          margin: 0;
        }
      }
    }
  }

  .rtl-page .section44 .items .item:nth-child(2) {
    margin: 0;
  }
}

@media only screen and (max-width: 991px) {
  .section44 {
    .items {
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .item {
        max-width: 470px;
        order: unset !important;
        align-items: flex-start;
        padding: 0;

        &::before {
          top: -20px;
          bottom: -20px;
          height: auto;
        }

        &:after {
          content: none;
        }

        &:nth-child(2n) {
          padding: 0;

          &:before {
            top: -20px;
            bottom: -20px;
          }

          .txt-h {
            padding-top: 0;
          }
        }

        &:last-child:before {
          height: 53px;
          bottom: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section44 {
    padding: 148px 0 60px;
    background: linear-gradient(224deg, #48D0A4 -1.76%, #9CE5CB 55.47%);

    &:before {
      width: 91px;
      height: 80px;
      top: -80px;
      // background-image: url(#{$img-path}/corner-section39-1-sm.png);
    }

    h2 {
      margin-bottom: 50px;

      &:before {
        font-size: 100px;
      }

      &:after {
        bottom: -20px;
      }
    }

    .items {
      .item {
        max-width: 290px;

        &::before {
          left: 35px;
        }

        &:nth-child(1):before {
          top: 33px;
        }

        .img-h {
          width: 70px;
          height: 70px;
          margin-top: 33px;
        }

        .txt-h {
          .number {
            font-size: 26px;
            line-height: 40px;
            margin-bottom: 10px;
            margin-bottom: 5px;
          }

          .year {
            font-size: 80px;
            line-height: 70px;
            margin-bottom: 8px;
          }

          .caption {
            font-size: 18px;
            line-height: 26px;
            padding-right: 0;
            margin-top: -33px;
            max-width: 200px;
          }

          > div {
            gap: 14px;
          }

          *[class*=icon-] {
            font-size: 32px;
          }
        }
      }
    }
  }

  .rtl-page .section44 {
    background: linear-gradient(136deg, #48D0A4 -1.76%, #9CE5CB 55.47%);
  }

  .rtl-page .section44 .items .item:before {
    left: auto;
    right: 35px;
  }

  .rtl-page .section44 .items .item:nth-child(2) {
    margin: 0;
  }

  .rtl-page .section44 .items .item .txt-h .caption {
    padding-right: 0;
    padding-left: 0;
  }
}

.section45 {
  padding: 120px 0;

  .txt-h {
    margin-left: -100px;
    padding-bottom: 40px;
    color: rgba(48, 48, 48, 0.75);
    font-size: 20px;
    line-height: 32px;

    strong{font-weight: 600;}
  }

  .img-h {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rtl-page .section45 .txt-h {
  margin-left: 0;
  margin-right: -100px;
}

@media only screen and (max-width: 768px) {
  .section45 {
    padding: 60px 0;

    .txt-h {
      margin: 0;
      font-size: 18px;
      padding: 0 0 5px;
    }

    .img-h {
      margin: 0 -15px;
    }
  }

  .rtl-page .section45 .txt-h {
    margin: 0;
  }
}

.section46 {

  .hold {
    position: relative;
    color: var(--white-color);
    padding: 120px 15px 305px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 35px;
      background: #131a46 url("#{$img-path}/section46-hold.jpg") no-repeat 50% / cover;
    }

    .txt-h {
      position: relative;
      max-width: 580px;
      margin: 0 auto;

      h2 {
        color: var(--white-color);
      }

      .btn-hold {
        padding-top: 30px;
        padding-bottom: 20px;
        .button {
          min-width: 200px;
        }
      }
    }
  }

  .img-h {
    margin-top: -330px;
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.rtl-page .section46 .hold:before {
  transform: scaleX(-1);
}

@media only screen and (max-width: 768px) {
  .section46 {

    .hold {
      margin: 0 -1px;
      padding: 40px 15px 232px;

      &:before {
        background: #131a46 url("#{$img-path}/section46-hold-sm.jpg") no-repeat 0 0 / cover;
      }

      .txt-h {
        .btn-hold {
          padding-top: 10px;
          padding-bottom: 30px;
          .button{
            min-width: 180px;
          }
        }
        .addition-txt{
          font-size: 1.6rem;
          line-height: 3.2rem;
        }
      }

    }

    .img-h {
      margin: -245px -330px -250px;
    }

  }
}

.section47 {
  position: relative;
  padding: 170px 0 0;
  min-height: 930px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 930px;
    background: #010407;
  }

  &:after {
    content: "";
    position: absolute;
    width: 711px;
    height: 303px;
    top: 0;
    right: 50%;
    margin-right: -725px;
    background: url("#{$img-path}/decor-section47-1.png") no-repeat 0 0 / cover;
  }

  .txt-h {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    color: var(--white-color);
    max-width: 590px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    .h1 {
      color: var(--white-color);

      > strong {
        background: linear-gradient(91deg, #46CD98 35.11%, #159E84 44.04%, #2D6776 55.63%);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        &::selection {
          background-clip: initial;
          -webkit-text-fill-color: initial;
        }
      }
    }


    .btn-hold {
      padding-top: 20px;

      .button-play {
        background: #5A5A5A;
        .txt {
          padding: 18px 28px;
          min-width: 156px;
        }

        .icon {
          padding: 18px 8px 18px 0;

          .icon-play {
            padding: 0 25px;
          }
        }
      }
    }
  }

  .img-h {
    margin-top: -165px;
    position: relative;
    display: flex;
    justify-content: center;
  }
}

.rtl-page .section47:after {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -725px;
  transform: scaleX(-1);
}

.rtl-page .section47 .txt-h .h1 > strong {
  background: linear-gradient(-91deg, #46CD98 35.11%, #159E84 44.04%, #2D6776 55.63%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rtl-page .section47 .text .btn-hold .button-play .icon {
  padding: 18px 0 18px 8px;
}

@media only screen and (max-width: 768px) {
  .section47 {
    padding-top: 60px;
    padding-bottom: 0;

    min-height: 706px;

    &:before {
      height: 706px;
    }

    .img-h {
      margin-top: 58px;
      margin: 5px -75px 0px;
    }
  }

}

@media only screen and (max-width: 479px) {
  .section47 {
    .txt-h {
      p {
        letter-spacing: -.2px;
      }
    }
  }
}


.section48 {
  padding: 90px 0 96px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 514px;
    height: 387px;
    bottom: 0;
    right: 50%;
    margin-right: -675px;
    background: url("#{$img-path}/decor-section48-1.png") no-repeat 0 0 / cover;
  }

  &:after {
    content: "";
    position: absolute;
    width: 796px;
    height: 314px;
    bottom: 0;
    right: 50%;
    margin-right: -1000px;
    background: url("#{$img-path}/decor-section48-2.png") no-repeat 0 0 / cover;
  }

  h2 {
    br {
      display: none;
    }
  }

  .items {
    padding: 20px 0 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 716px;
      height: 432px;
      top: -200px;
      left: -420px;
      background: url("#{$img-path}/decor-section48-3.png") no-repeat 0 0 / cover;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 13px 20px;
      font-size: 18px;
      line-height: 28px;
      color: rgba(48, 48, 48, 0.75);

      li {
        display: flex;
        position: relative;
        padding-left: 40px;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          width: 40px;
          height: 40px;
          border: 1px solid #D9D9D9;
          border-radius: 50%;
        }

        > span {
          display: inline-flex;
          padding: 5px 22px;
          border: 1px solid #D9D9D9;
          border-radius: 20px;
          min-width: 194px;
          justify-content: center;

          &:before,
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: #D9D9D9;
            margin: 11px 0 0 11px;
          }

          &:before {
            left: 0;
            width: 16px;
            height: 2px;
            top: 7px;
          }

          &:after {
            height: 16px;
            width: 2px;
            left: 7px;
          }
        }

      }
    }
  }
}

.rtl-page .section48:before {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -675px;
}

.rtl-page .section48:after {
  right: auto;
  left: 50%;
  margin-right: 0;
  margin-left: -1000px;
  transform: scaleX(-1);
}

.rtl-page .section48 .items:before {
  left: auto;
  right: -420px;
  transform: scaleX(-1);
}

.rtl-page .section48 .items ul li {
  padding-left: 0;
  padding-right: 40px;
}

.rtl-page .section48 .items ul li:before {
  left: auto;
  right: 0;
}

.rtl-page .section48 .items ul li > span:before {
  left: auto;
  right: 0;
  margin: 11px 11px 0 0;
}

.rtl-page .section48 .items ul li > span:after {
  left: auto;
  right: 7px;
  margin: 11px 11px 0 0;
}

@media only screen and (max-width: 768px) {
  .section48 {
    padding-top: 60px;
    padding-bottom: 60px;

    h2 {
      br {
        display: inline;
      }
    }

    .items {
      padding: 0;

      ul {
        flex-direction: column;
        align-content: center;

        li {
          span {
            min-width: 1px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .section48 {
    .items {
      ul {
        align-content: flex-start;
      }
    }
  }
}

.section49 {
  background: rgba(191, 224, 212, 0.80);

  .main-holder {
    display: flex;
  }

  .txt-h {
    width: 270px;
    padding: 45px 0;

    h2 {
      font-size: 38px;
      line-height: 48px;
    }
  }

  .items {
    display: flex;
    flex: 1;
    counter-reset: items;

    .item {
      flex: 1;
      padding: 65px 30px 40px;
      border: 1px solid var(--white-color);
      border-width: 0 1px;
      margin: 0 0 0 -1px;
      color: rgba(48, 48, 48, 0.75);
      font-size: 16px;
      line-height: 24px;
      counter-increment: items;

      &:before {
        content: "0" counter(items);
        display: block;
        color: #303030;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 10px;
      }


      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      p {
        max-width: 208px;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .section49 {
    .main-holder {
      display: block;
    }

    .txt-h {
      width: auto;
      padding-bottom: 0;
    }

    .items {
      margin: 0 -15px;

      .item {
        border-width: 1px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section49 {

    .txt-h {
      padding-top: 60px;

      h2 {
        font-size: 28px;
        line-height: 38px;
      }
    }

    .items {
      flex-direction: column;
      border: 0;

      .item {
        border-width: 1px 0;
        margin: -1px 0 0;
        padding: 50px 15px;
        line-height: 28px;

        p {
          max-width: none;
        }
      }
    }
  }
}

.section50 {
  padding: 90px 0 320px;
  background: linear-gradient(0deg, #FFF 0%, #CDCABF 100%);

  &.v2{
    background: linear-gradient(180deg, #9F9FCD 0%, #FFF 100%);
  }

  &.height {
    padding-bottom: 9rem;
  }

  .txt-h {
    max-width: 960px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;

    .btn-hold {
      padding-top: 20px;

      .button-play {
        .txt {
          padding: 18px;
          font-size: 16px;
          min-width: 156px;
        }

        .icon {
          padding: 18px 8px 18px 0;

          .icon-play {
            padding: 0 25px;
          }
        }
      }
    }

    .plans-include {
      padding: 40px 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      gap: 38px;
      color: #1A1A1A;

      p {
        margin-bottom: 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 38px;

        li {
          display: flex;
          gap: 8px;
          position: relative;

          &:after,
          &:before {
            width: 20px;
            height: 20px;
            margin: 2px;
          }

          &:after {
            font-family: 'icomoon';
            content: "\e927";
            font-size: 12px;
            line-height: 20px;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            color: var(--white-color);
          }

          &:before {
            content: "";
            border-radius: 4px;
            background: #289D3B;
          }
        }
      }
    }

  }
}

.rtl-page .section50 .txt-h .btn-hold .button-play .icon {
  padding: 18px 0 18px 8px;
}

.rtl-page .section50 .txt-h .plans-include ul li:after {
  left: auto;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .section50 {
    padding-top: 60px;
    padding-bottom: 216px;
    &.height {
      padding-bottom: 6rem;
    }
    .txt-h {
      font-size: 18px;
      line-height: normal;
      font-weight: 300;

      p{
        margin-left: .5rem;
        margin-right: .5rem;
        strong{font-weight: 600;}
      }

      .plans-include {
        padding-top: 20px;
        padding-bottom: 30px;
        gap: 20px;

        ul {
          justify-content: center;
          gap: 20px;
        }
      }

      .btn-hold {
        .button-play {
          .txt {
            font-size: 18px;
            min-width: 148px;
          }

          .icon {
            .icon-play {
              padding: 0 30px;
            }
          }
        }
      }
    }
    &.v2{
      .txt-h {
        .plans-include{
          padding-top: 10px;
        }
      }
    }
  }
}

.section51 {
  margin-top: -320px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .section51 {
    margin-top: -206px;
    padding-bottom: 0;
  }
}

.section52 {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .section52 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.pricing-plans {
  max-width: 1064px;
  margin: 0 auto;
  text-align: center;

  .txt-h {
    max-width: 520px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 32px;

    p {
      margin-bottom: 30px;

      strong {
        font-weight: 400;
      }
    }
  }

  input[type=radio] {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    + label {
      position: relative;
      display: inline-flex;
      gap: 8px;
      vertical-align: top;
      font-size: 16px;
      line-height: 22px;
      color: #1A1A1A;
      margin: 10px 15px 30px;
      background: transparent;
      padding: 0;

      &:before {
        content: "";
        width: 16px;
        height: 16px;
        border: 1px solid #D1D2D4;
        background: var(--white-color);
        border-radius: 50%;
        margin-top: 2px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 4px;
        top: 4px;
        margin-top: 2px;
        width: 8px;
        height: 8px;
        background: #289D3B;
        border-radius: 50%;
        opacity: 0;
        transition: all .3s;
      }
    }

    &:checked {
      + label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  #pricing-websites-plans-annual:checked ~ .items .item .item-heading .price {
    display: none;
  }

  #pricing-websites-plans-annual:checked ~ .items .item .item-heading .price.annual {
    display: block;
  }

  #pricing-business-plans-annual:checked ~ .items .item .item-heading .price {
    display: none;
  }

  #pricing-business-plans-annual:checked ~ .items .item .item-heading .price.annual {
    display: block;
  }

  .items {
    display: flex;
    padding: 60px 0 20px;
    text-align: initial;
    margin-bottom: 35px;

    .item {
      flex: 1;

      .item-main {
        position: relative;
        min-height: 100%;
        border-radius: 20px;
        border: 1px solid #D9D9D9;
        background: var(--white-color);

        &:after {
          content: "";
          position: absolute;
          top: 21px;
          right: 23px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: var(--white-color);
        }
      }

      .specification {
        display: none;
      }

      .recommended {
        border-radius: 20px;
        background: linear-gradient(90deg, #64E1B3 0%, #00AC7C 100%);
        color: var(--white-color);
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        margin: 6px 7px -16px;
        padding: 5px 20px;
      }

      .item-heading {
        text-align: center;
        border-bottom: 1px solid #000;
        margin: 0 12px;
        padding: 32px 18px 20px;

        h3 {
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 15px;
        }

        p {
          color: rgba(48, 48, 48, 0.75);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          margin-bottom: 28px;
        }

        .price {
          display: block;
          font-size: 55px;
          font-style: normal;
          font-weight: 600;
          line-height: 37px;
          color: #48D0A4;
          margin-bottom: 1rem;

          &.annual {
            display: none;
          }

          sup {
            display: inline-block;
            top: -1.1rem;
            font-size: 32px;
            font-weight: 300;
            padding: 0 5px;
          }

          sub {
            display: inline-block;
            bottom: 0;
            font-size: 24px;
            font-weight: 300;
          }

          span {
            font-weight: 400;
          }
          .old-price{
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            font-size: 32px;
            line-height: 27px;
            margin-inline-end: 10px;
            color: rgba(48, 48, 48, 0.75);
            text-decoration: line-through;
          }
        }
        .btn-h{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          min-height: 77px;
          p{
            margin-bottom: 5px;
          }
        }
      }

      .item-content {
        padding: 50px 40px 50px;
        font-size: 22px;
        line-height: 28px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 18px;
          li{
            strong{
              font-weight: 600;
            }
          }
        }

        p {
          margin-bottom: 35px;
        }

        > *:last-child {
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        z-index: 1;
        .item-main {
          margin: -50px -5px -20px;
          min-height: calc(100% + 70px);
          box-shadow: 0px 0px 40px 0px rgba(95, 95, 155, 0.50);
        }
      }

      &:first-child {
        .item-main {
          margin: 0 -10px 0 0;

          &:after {
            background: #48D0A4;
          }
        }
      }

      &:last-child {
        .item-main {
          margin: 0 0 0 -10px;

          &:after {
            background: #48D0A4;
          }
        }
      }
    }
  }

  .specification {
    text-align: initial;
    color: rgba(48, 48, 48, 0.75);
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .btn-hold {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    .button {
      border-radius: 22px;
      font-size: 16px;
      font-weight: 400;
      min-width: 233px;
    }

    .button-play {

      .txt {
        padding: 18px;
        font-size: 16px;
        min-width: 156px;
      }

      .icon {
        padding: 18px 8px 18px 0;

        .icon-play {
          padding: 0 25px;
        }
      }
    }
  }

  &.pricing-business-plans {
    max-width: 1072px;

    .items {
      // padding-bottom: 35px;
      // margin-bottom: 0;

      .item {
        &:first-child {
          .item-main {
            &:after {
              background: #846EF0;
            }
          }
          .item-content {
            ul{
              gap: 9px;
            }
          }
        }

        &:last-child {
          .item-main {
            &:after {
              background: #846EF0;
            }
          }
        }

        &:nth-child(2) {
          .item-main {
            margin-bottom: -35px;
          }
        }
        
        .item-heading{
            .price{
              color: #8A6FF9;
              .old-price{
                font-size: 25px;
              }
            }          
        }

        .item-content {
          padding-bottom: 50px;
        }

        .recommended {
          background: linear-gradient(90deg, #8A6FF9 0%, #2D6577 100%);
          margin-bottom: -9px;
        }

      }
    }

    .specification {
      margin-top: -12px;
    }
  }
}

.rtl-page .pricing-plans .items .item .item-main:after {
  right: auto;
  left: 23px;
}

.rtl-page .pricing-plans .items .item:first-child .item-main {
  margin: 0 0 0 -10px;
}

.rtl-page .pricing-plans .items .item:last-child .item-main {
  margin: 0 -10px 0 0;
}

.rtl-page .pricing-plans .items .item .recommended {
  background: linear-gradient(-90deg, #64E1B3 0%, #00AC7C 100%);
}

.rtl-page .pricing-business-plans .items .item .recommended {
  background: linear-gradient(-90deg, #8A6FF9 0%, #2D6577 100%);
}

.rtl-page .pricing-plans.pricing-business-plans .items .item .recommended {
  background: linear-gradient(-90deg, #8A6FF9 0%, #2D6577 100%);
}

.rtl-page .pricing-plans input[type="radio"] + label:after {
  left: auto;
  right: 4px;
}

.rtl-page .pricing-plans .btn-hold .button-play .icon {
  padding: 18px 0 18px 8px;
}

@media only screen and (max-width: 991px) {
  .pricing-plans {
    .items {
      .item {
        .item-content {
          font-size: 18px;
          padding-left: 29px;
          padding-right: 29px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .pricing-plans {
    .txt-h {
      font-size: 16px;
      line-height: normal;

      h2,
      p {
        margin-bottom: 10px;
      }

      h2 + p {
        margin-top: 20px;
      }

      p strong {
        font-weight: 700;
      }
    }

    > .specification {
      display: none;
    }

    .btn-hold .button{
      font-weight: normal;
      font-size: 14px;
    }

    .items {
      flex-direction: column;
      gap: 30px;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 30px;

      .item {
        .specification {
          display: block;
          margin-bottom: 0;
          font-size: 16px;
          line-height: 16px;
          margin-top: 20px;
          margin-bottom: -5px;
        }
        .recommended{
          margin-bottom: 0 !important;
        }

        .item-main {
          &:after {
            right: 15px;
          }
        }

        &:first-child .item-main,
        &:last-child .item-main {
          margin: 0;
        }

        &:nth-child(2) .item-main {
          margin: 0;
        }

        .item-heading {
          padding: 26px 0 24px;
          .price{
            margin-left: -12px;
            margin-right: -12px;            
          }
          .btn-h{
            .button{
              min-width: 180px;
              font-size: 16px;
            }
          }
        }
        
        .item-content {
          padding: 30px 29px 24px;
          font-size: 18px;

          ul {
            gap: 8px !important;
          }

          p {
            margin-bottom: 12px;
          }

          > *:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &.pricing-business-plans {
      .items {
        .item {
          .specification {
            margin-bottom: -2px;
          }

          &:nth-child(2) .item-main {
            margin: 0;
          }

          &:last-child .item-content p {
            margin-bottom: 17px;
          }
          .item-heading{
            .price{
              font-size: 50px;
            }
          }
          .item-content {
            padding-bottom: 20px;
          }
        }
      }
    }
  }

  .rtl-page .pricing-plans .items .item:first-child .item-main,
  .rtl-page .pricing-plans .items .item:last-child .item-main {
    margin: 0;
  }
}

.section53 {
  padding-top: 40px;
  padding-bottom: 40px;
  .btn-hold {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    .button {
      border-radius: 22px;
      font-size: 14px;
      font-weight: 400;
      min-width: 325px;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    .item {
      width: 100%;
      max-width: 370px;
      background: linear-gradient(180deg, #D7E7F3 0%, #FFF 100%);
      padding: 34px 20px 24px;
      color: rgba(48, 48, 48, 0.75);
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      min-height: 184px;

      .item-heading {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 20px;

        *[class*=icon-] {
          font-size: 34px;
          display: inline-block;
        }

        h3 {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 0;
        }
      }

      > *:last-child {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        background: linear-gradient(0deg, #FFF 0%, #EFEDFF 100%);

        .item-heading {
          *[class*=icon-] {
            color: #615A8F;
          }
        }
      }

      &:nth-child(2) {
        background: linear-gradient(180deg, #D7E7F3 0%, #FFF 100%);

        .item-heading {
          *[class*=icon-] {
            color: #2272AF;
          }
        }
      }

      &:nth-child(3) {
        background: linear-gradient(0deg, #FFF 0%, #CDEED3 100%);

        .item-heading {
          *[class*=icon-] {
            color: #1A7128;
          }
        }
      }

      .payments {
        display: flex;
        justify-content: center;
        gap: 15px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section53 {
    padding-top: 60px;
    padding-bottom: 60px;

    .items {
      gap: 10px;

      .item {
        .payments {
          gap: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .section53 {
    .items {
      .item {
        max-width: none;
      }
    }
  }
}

.section54 {
  padding-top: 40px;
}

@media only screen and (max-width: 768px) {
  .section54 {
    padding-top: 60px;
    margin-bottom: 0;
  }
}

.section55 {
  padding-top: 40px;
}

@media only screen and (max-width: 768px) {
  .section55 {
    padding-top: 60px;
  }
}

.acc-types {
  display: flex;
  position: relative;
  padding-bottom: 60px;

  &:before {
    content: "";
    position: absolute;
    top: 318px;
    bottom: 0;
    background: linear-gradient(130deg, #FBF8EE 28.27%, rgba(255, 255, 255, 0.00) 80.1%);
    width: 100vw;
    left: calc(-50vw + 50%);
  }

  .item-heading {
    height: 318px;
    padding: 49px 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    position: relative;

    h3 {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 25px;
    }

    .price {
      display: block;
      font-size: 55px;
      font-style: normal;
      font-weight: 600;
      line-height: 37px;
      color: #48D0A4;
      margin-bottom: 25px;

      &.annual {
        display: none;
      }

      sup {
        display: inline-block;
        top: -1.1rem;
        font-size: 32px;
        font-weight: 300;
        padding: 0 5px;
      }

      sub {
        display: inline-block;
        bottom: 0;
        font-size: 24px;
        font-weight: 300;
      }

      span {
        font-weight: 400;
      }
    }

    > *:last-child {
      margin-bottom: 0;
    }

    p {
      color: rgba(48, 48, 48, 0.75);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      margin-bottom: 10px;
    }

    .recommended {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 20px;
      border-radius: 20px;
      background: linear-gradient(90deg, #64E1B3 0%, #00AC7C 100%);
      color: var(--white-color);
      font-size: 14px;
      line-height: 29px;
      text-align: center;
      padding: 0 15px;
    }

    .mprice{
      font-size: 26px;
      line-height: 32px;
      font-weight: 400;
      color: rgba(48, 48, 48, 0.75);
      margin-bottom: .8rem;
      sub{
        font-size: 15px;
        line-height: 32px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        top: 3px;
      }
    }
    .btn-h{
      min-height: 8.4rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .item-content {

    .item-ttl {
      position: relative;
      height: 50px;
      margin-bottom: 15px;

      strong {
        display: none;
        align-items: center;
        height: 100%;
        font-size: 24px;
        line-height: 20px;
        padding: 5px 15px;
        padding-inline-start: 30px;
        background: linear-gradient(90deg, rgba(205, 202, 191, 0.00) 0%, rgba(205, 202, 191, 0.40) 100%);
      }
    }

    ul {
      margin: 0 0 25px;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        height: 45px;
        font-size: 22px;
        line-height: 22px;

        .item-name {
          display: none;
        }

        .item-value {
          color: #259E3F;

          *[class*=icon] {
            font-size: 18px;
          }

          *[class*=icon-close] {
            font-size: 14px;
            color: #DA0F52;
          }
        }
      }
    }
  }

  > .item {
    width: 300px;
    position: relative;

    .item-content {
      .item-ttl {
        &:before {
          content: none;
        }

        strong {
          display: flex;
        }
      }

      ul {
        li {
          justify-content: flex-start;
          padding-left: 48px;

          .item-name {
            display: block;
          }
        }
      }
    }
  }

  .items {
    flex: 1;
    display: flex;
    gap: 18px;

    .item {
      position: relative;
      flex: 1;
      background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);

      &:nth-child(2) {
        z-index: 1;
        border-radius: 20px 20px 0px 0px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
        box-shadow: 0px -10px 20px 0px rgba(12, 95, 68, 0.10);
      }

      .item-ttl {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% + 18px);
          height: 1px;
          background: #CDCABF;
        }
      }

      &:last-child {
        .item-ttl {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  &.acc-types-business {
    &:before {
      background: linear-gradient(-32deg, #FFF 30.7%, rgba(154, 153, 206, 0.50) 79.64%);
    }

    .item-heading {
      .price{
        color: #8A6FF9;
      }
      .recommended {
        background: linear-gradient(90deg, #6048FF 0%, #2D6577 100%);
      }
    }

    .items {
      .item {
        .item-ttl {
          &:before {
            background: #8A6FF9;
          }
        }
      }
    }

    .item-content {
      .item-ttl {
        strong {
          background: linear-gradient(90deg, rgba(138, 111, 249, 0.00) 0%, rgba(138, 111, 249, 0.40) 100%);
        }
      }
    }
  }
}

.rtl-page .acc-types:before {
  background: linear-gradient(-130deg, #FBF8EE 28.27%, rgba(255, 255, 255, 0.00) 80.1%);
}
.rtl-page .acc-types .item-heading .recommended {
  background: linear-gradient(-90deg, #64E1B3 0%, #00AC7C 100%);
}
.rtl-page .acc-types .item-content .item-ttl:before{
  left: auto;
  right: 0;
}
.rtl-page .acc-types .item-content .item-ttl strong {
  background: linear-gradient(-90deg, rgba(205, 202, 191, 0.00) 0%, rgba(205, 202, 191, 0.40) 100%);
}

.rtl-page .acc-types > .item .item-content ul li {
  padding-right: 48px;
  padding-left: 10px;
}

.rtl-page .acc-types.acc-types-business:before{
  background: linear-gradient(32deg, #FFF 30.7%, rgba(154, 153, 206, 0.50) 79.64%);
}
.rtl-page .acc-types.acc-types-business .item-heading .recommended {
  background: linear-gradient(-90deg, #6048FF 0%, #2D6577 100%);
}

.rtl-page .acc-types.acc-types-business .item-content .item-ttl strong {
  background: linear-gradient(-90deg, rgba(138, 111, 249, 0.00) 0%, rgba(138, 111, 249, 0.40) 100%);
}

@media only screen and (max-width: 1200px) {
  .acc-types {
    > .item {
      display: none;
    }

    .item-content {
      ul {
        li {
          font-size: 18px;
          gap: 20px;
          justify-content: flex-start;

          .item-name {
            display: block;
            width: 165px;
            width: 61%;
          }

          .item-value {
            flex: 1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .acc-types {
    > .item {
      display: none;
    }

    .item-heading {
      .recommended {
        // left: 25px;
        // right: 25px;
        // top: 10px;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        width: 240px;
        margin: -26px auto 18px;
      }
    }

    .item-content {
      .item-ttl {
        strong {
          display: flex;
          padding: 5px 20px;
        }
      }

      ul {
        li {
          font-size: 18px;
          gap: 20px;
          justify-content: flex-start;

          .item-name {
            display: block;
            width: 165px;
            width: 61%;
          }

          .item-value {
            flex: 1;
          }
        }
      }
    }

    .items {
      flex-direction: column;
      justify-content: center;
      gap: 30px;

      .item {
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        border-radius: 10px 10px 0px 0px;

        &:nth-child(2) {
          background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
          border-radius: 10px 10px 0px 0px;
          box-shadow: none;
        }

        .item-ttl {
          &:before {
            content: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .acc-types {
    padding-top: 40px;

    &:before {
      top: 0;
    }

    .item-heading {
      padding: 37px 20px 24px;
      height: auto;
      
      p{margin: 0 0 15px;}
      
      .btn-h{
        min-height: auto;
        .button{
          min-width: 180px;
        }
      }
    }

    .item-content {
      ul {
        margin-bottom: 33px;
        &:last-child{margin-bottom: 0;}
        li{
          height: 46px;
        }
      }

      .item-ttl {
        margin-bottom: 5px;
      }
    }

    .items {
      flex-direction: column;
      gap: 40px;

      .item {
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        border-radius: 10px 10px 0px 0px;

        &:nth-child(2) {
          background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
          border-radius: 10px 10px 0px 0px;
          box-shadow: none;
        }

      }
    }
  }
}

.section56{
  position: relative;
  margin-top: 60px;
  margin-bottom: 115px;

  &:before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, #FFF 0.05%, #D6D6DE 43.91%, #9A99CE 99.95%);
    border-radius: 0 273px 273px 0;
  }
  .main-holder{
    position: relative;
  }
  .hold{
    flex-direction: row-reverse;
    align-items: center;
  }
  .img-h{
    text-align: center;
    flex: 1;
    margin: 42px 35px -248px -555px;
  }
  .txt-h{
    text-align: center;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 60px;
    .hold{
      flex-direction: column;
    }
    .img-h{
      margin: 0 -15px -140px -170px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 140px;
    
    &:before{
      border-radius: 0;
    }
    .txt-h{
      padding-bottom: 20px;
      h2{
        margin-bottom: 40px;
      }
      .btn-hold{
        .button{
          min-width: 248px;
        }
      }
    }
    .img-h{
      margin: 0 -15px -200px;
    }
  }

}
.rtl-page .section56{
  &:before{
    background: linear-gradient(-90deg, #FFF 0.05%, #D6D6DE 43.91%, #9A99CE 99.95%);
    border-radius: 273px 0 0 273px;
  }
  .img-h{
    margin: 42px -405px -248px -115px;
  }
  @media only screen and (max-width: 991px) {
    .img-h{
      margin: 0 -40px -140px -125px;
    }
  }
  @media only screen and (max-width: 768px) {
    &:before{
      border-radius: 0;
    }
    .img-h{
      margin: 0 -15px -200px;
    }
  }
}


.section57{
  position: relative;
  margin-top: 60px;
  margin-bottom: 115px;

  &:before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg, #FFF 0.07%, #CDCABF 99.9%);
    border-radius: 0 273px 273px 0;
  }
  .main-holder{
    position: relative;
  }
  .hold{
    flex-direction: row-reverse;
    align-items: center;
  }
  .img-h{
    text-align: center;
    flex: 1;
    margin: -25px -205px -213px -490px;
  }
  .txt-h{
    text-align: center;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 60px;
    .hold{
      flex-direction: column;
    }
    .img-h{
      margin: 0 -175px -125px -100px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 140px;
    
    &:before{
      border-radius: 0;
    }
    .txt-h{
      padding-bottom: 20px;
      h2{
        margin-bottom: 40px;
      }
      .btn-hold{
        .button{
          min-width: 248px;
        }
      }
    }
    .img-h{
      margin: 0 -15px -200px;
    }
  }

}
.rtl-page .section57{
  &:before{
    background: linear-gradient(-90deg, #FFF 0.07%, #CDCABF 99.9%);
    border-radius: 273px 0 0 273px;
  }
  .img-h{
    margin: -25px -630px -213px -65px;
  }
  @media only screen and (max-width: 991px) {
    .img-h{
      margin: 0 -175px -125px -100px;
    }
  }
  @media only screen and (max-width: 768px) {
    &:before{
      border-radius: 0;
    }
    .img-h{
      margin: 0 -15px -200px;
    }
  }
}
