@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?89z6j7');
  src: url('../fonts/icomoon.eot?89z6j7#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?89z6j7') format('truetype'),
  url('../fonts/icomoon.woff?89z6j7') format('woff'),
  url('../fonts/icomoon.svg?89z6j7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  // font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"] > span,
[class*=" icon-"] > span {
  display: none;
}

.icon-brand-fb:before {
  content: "\41";
}

.icon-brand-x:before {
  content: "\42";
}

.icon-brand-ig:before {
  content: "\43";
}

.icon-brand-in:before {
  content: "\44";
}

.icon-brand-yt:before {
  content: "\45";
}

.icon-brand-wa:before {
  content: "\46";
}

.icon-share:before {
  content: "\47";
}

.icon-menu-burger:before {
  content: "\e900";
}

.icon-profile:before {
  content: "\e901";
}

.icon-menu-arrow:before {
  content: "\e902";
}

.icon-list-bullet:before {
  content: "\e903";
}

.icon-cross:before {
  content: "\e904";
}

.icon-plus:before {
  content: "\e905";
}

.icon-minus:before {
  content: "\e906";
}

.icon-link:before {
  content: "\e907";
}

.icon-slider-arrow:before {
  content: "\e908";
}

.icon-pagination-arrow:before {
  content: "\e909";
}

.icon-pagination-arrows:before {
  content: "\e90a";
}

.icon-phone:before {
  content: "\e90b";
}

.icon-email:before {
  content: "\e90c";
}

.icon-location:before {
  content: "\e90d";
}

.icon-secure:before {
  content: "\e90e";
}

.icon-award:before {
  content: "\e90f";
}

.icon-crypto:before {
  content: "\e910";
}

.icon-profit:before {
  content: "\e911";
}

.icon-device:before {
  content: "\e912";
}

.icon-webtrader:before {
  content: "\e913";
}

.icon-mobile:before {
  content: "\e914";
}

.icon-feature1:before {
  content: "\e915";
}

.icon-feature2:before {
  content: "\e916";
}

.icon-feature3:before {
  content: "\e917";
}

.icon-feature4:before {
  content: "\e918";
}

.icon-arrowR:before {
  content: "\e919";
}

.icon-play:before {
  content: "\e91a";
}

.icon-arrSliderR:before {
  content: "\e91b";
}

.icon-arrSliderL:before {
  content: "\e91c";
}

.icon-sup1:before {
  content: "\e91d";
}

.icon-sup5:before {
  content: "\e91e";
}

.icon-sup4:before {
  content: "\e91f";
}

.icon-sup3:before {
  content: "\e92d";
}

.icon-ben1:before {
  content: "\e921";
}

.icon-ben9:before {
  content: "\e937";
}

.icon-ben8:before {
  content: "\e938";
}

.icon-uniE939:before {
  content: "\e939";
}

.icon-ben2:before {
  content: "\e93c";
}

.icon-ben4:before {
  content: "\e93d";
}

.icon-ben3:before {
  content: "\e93f";
}

.icon-ben6:before {
  content: "\e942";
}

.icon-ben7:before {
  content: "\e943";
}

.icon-clock:before {
  content: "\e944";
}

.icon-locationN:before {
  content: "\e945";
}

.icon-ben5:before {
  content: "\e946";
}

.icon-sol2:before {
  content: "\e947";
}

.icon-sol3:before {
  content: "\e948";
}

.icon-sol4:before {
  content: "\e949";
}

.icon-sol1:before {
  content: "\e94a";
}

.icon-arrSliderL1:before {
  content: "\e922";
  color: #289d3b;
}

.icon-arrSliderR1:before {
  content: "\e923";
  color: #289d3b;
}

.icon-arr-sliderR:before {
  content: "\e924";
  color: #5f6368;
}

.icon-arr-sliderL:before {
  content: "\e925";
  color: #5f6368;
}

.icon-calendar:before {
  content: "\e926";
  color: #0ca5d6;
}

.icon-check:before {
  content: "\e927";
  color: #fff;
}

.icon-payment:before {
  content: "\e928";
  color: #615a8f;
}

.icon-time:before {
  content: "\e929";
  color: #2272af;
}

.icon-security:before {
  content: "\e92a";
  color: #1a7128;
}

.icon-close:before {
  content: "\e92b";
  color: #da0f52;
}

.icon-msg:before {
  content: "\e92c";
}

.rtl-page .icon-link:before {
  display: inline-block;
  transform: scaleX(-1);
}

.icon-slL:before {
  content: "\e92e";
}

.icon-slR:before {
  content: "\e92f";
}
