$site-domain: "";
@import "variables";
@import "button";
@import "ui";
@import "fonts";
@import "aos/dist/aos.css";
@import "slick";

.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter .3s;

}

.lazy-load-image-background.blur > img {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
}

$img-path: "#{$site-domain}/Media/Image";
$background-color: #010407;
.spinner-h {
  position: fixed;
  background: transparent;
  background: #fbf9f7;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  transition: all 0.5s;
}

.box {
  position: relative;
  background: var(--tertiary-bg);
  color: var(--main-color);
  border: .1rem solid var(--main-border-color);
  margin: 0 0 3rem;

  .box-heading {
    padding: .5rem 2rem;
  }

  .box-content {
    padding: 2rem;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .box-footer {
    padding: .5rem 2rem;
  }

  &.box02 {
    border: 0;
    border-radius: 2rem;
    box-shadow: var(--main-box-shadow);
  }
}


.error__page {
  background: #F1F1F3;

  main {
    // min-height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.error__page-content {
  padding: 0 0 23rem;

  & > img,
  & > svg {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 7rem;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.page-notfound-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  padding: 10rem 0;

  & > img,
  & > svg {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 7rem;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}


/* contactus */
.contactus__page main {
  padding-bottom: 0;
}

.btn-popup-video {
  position: absolute;
  background: transparent;
  border: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    bottom: 30px;
    left: 20px;
    content: '';
    width: 130px;
    height: 130px;
    background: url("#{$img-path}/icon_play.svg") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  @media only screen and (max-width: 480px) {
    &:before {
      width: 110px;
      height: 110px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 7px;
    }
  }

}

.contactus__details {
  padding: 4rem 0 10rem;
  text-align: center;
  position: relative;

  &:before {
    position: absolute;
    bottom: -185px;
    left: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section__contact-7.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }
  h2{
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 2rem 0 7rem;
    h2{
      margin-bottom: 3rem;
    }
    &:before {
      bottom: -80px;
      width: 91px;
      height: 80px;
    }
  }
}


.contact__helpers {
  &_items {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-bottom: 125px;
  }

  &_item {
    display: flex;
    width: 123px;
    height: 123px;
    color: var(--white-color);
    font-size: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 8px solid rgb(255, 255, 255, 0.5);
    background: rgba(72, 208, 164, 1);
    transition: all 0.8s ease;

    em, i {
      color: var(--white-color);
      position: relative;
      top: 4px;
      display: flex;
    }

    &.email {
      background: rgba(246, 208, 63, 1);
    }

    &.msg {
      background: rgba(92, 91, 162, 1);
    }

    &:hover {
      border: 60px solid rgb(255, 255, 255, 0.5);
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 768px) {
    &_items {
      gap: 20px;
      flex-direction: column;
      margin-bottom: 40px;
      align-items: center;
    }

    &_item {
      display: flex;
      width: 123px;
      height: 123px;
      color: var(--white-color);
      font-size: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 8px solid rgb(255, 255, 255, 0.5);
      background: rgba(72, 208, 164, 1);
      transition: all 0.8s ease;
    }
  }

}

.contact__social {
  &_items {
    display: flex;
    gap: 180px;

    .social_h {
      display: flex;
      gap: 48px;

      a {
        display: flex;
        width: 76px;
        height: 76px;
        color: #010202;
        font-size: 30px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 4.754px 4.754px 9.508px rgba(0, 0, 0, 0.12);
        background: var(--white-color);
        transition: all 0.8s ease;

        &:hover {
          text-decoration: none;
          background: #010202;
          color: var(--white-color);
        }

        .icon-brand-in {
          font-size: 26px;
        }

      }
    }
  }

  &_item {
    text-align: initial;
    align-items: center;
    color: #303030;
    font-size: 32px;
    line-height: 38px;

    &:last-of-type {
      flex: 1;
    }
  }

  @media only screen and (max-width: 1200px) {
    &_items {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }
    &_item {
      text-align: center;
    }
  }

  @media only screen and (max-width: 570px) {
    &_items {
      .social_h {
        gap: 14px 30px;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &_item {
      font-size: 28px;
      line-height: 38px;
    }
  }
}


.contactus__banner {
  position: relative;
  padding: 13rem 0 21rem;
  background: linear-gradient(180deg, #E2E2E2 9.12%, #F4F4F4 36.27%, #FFF 76.98%);
  color: rgba(48, 48, 48, 0.75);
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  z-index: 1;
  min-height: 927px;
  min-height: 745px;

  .sub-ttl {
    position: relative;
    top: -44px
  }

  .heading-ttl {
    position: relative;
    display: inline-block;
    padding: 0 16rem;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: url(#{$img-path}/contactus-pin.png) 0 0 no-repeat;
      width: 128px;
      height: 144px;
      background-size: 100%;
    }

    .h1 {
      color: var(--white-color);
      text-align: center;
      text-shadow: 0px 4px 46.3px rgba(0, 0, 0, 0.10);
      font-size: 100px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 0;
    }

    .h2 {
      color: #303030;
      font-size: 69px;
      line-height: 75px;
      position: relative;
      top: -44px;
      margin-bottom: 1rem;
    }
  }

  .btn-hold {
    margin-top: -10px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(#{$img-path}/contactus-banner.png) 0 0 no-repeat;
    background-size: 100%;
    width: 1147px;
    height: 632px;
  }

  &:after {
    position: absolute;
    top: -1px;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section__contact.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  .main-holder {
    text-align: center;
    position: relative;
  }

  @media only screen and (max-width: 991px) {
    padding: 25rem 0 45rem;
    .sub-ttl {
      position: relative;
      top: -44px
    }

    .heading-ttl {
      padding: 0;

      &:before {
        top: -17rem;
        width: 104px;
        height: 125px;
        margin: 0 auto;
        right: 0;
      }

      .h1 {
        font-size: 70px;
      }

      .h2 {
        font-size: 50px;
        line-height: 55px;
        top: -35px
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 11.5rem 0 0;
    margin-bottom: -2.2rem;
    min-height: auto;
    .heading-ttl:before {
      top: -14.5rem;
      width: 82px;
      height: 97px;
    }
    .heading-ttl .h1 {
      font-size: 49px;
    }
    .heading-ttl .h2 {
      font-size: 30px;
      line-height: 35px;
      top: -35px;
      margin-bottom: 4rem;
    }
    .btn-hold {
      margin-top: -20px;

      .button {
        min-width: 180px;
      }
    }

    &:before {
      width: 100%;
      height: 100%;
      background: url(#{$img-path}/contactus-banner-sm.png) 0 0 no-repeat;
      background-size: 100%;
    }

    &:after {
      width: 91px;
      height: 80px;
    }
  }
}


.contactus__top_decor {
  position: relative;
  height: 8rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section__contact-9.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  .main-holder {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      right: -10rem;
      content: '';
      width: 766px;
      height: 389px;
      background: url("#{$img-path}/decor-section__contact-3.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }
  }

  @media only screen and (max-width: 480px) {
    height: 8rem;

    &:after {
      width: 91px;
      height: 80px;
    }

    .main-holder {
      &:before {
        width: 297px;
        height: 333px;
        top: -5rem;
      }
    }
  }
}

.contactUs-form {
  margin: -43rem 0 8rem;
  position: relative;
  z-index: 9;

  .main-holder {
    position: relative;

    &:before {
      position: absolute;
      top: -20rem;
      left: -25rem;
      content: '';
      width: 702px;
      height: 627px;
      background: url("#{$img-path}/decor-section__contact-4.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }
  }

  .form {
    max-width: 770px;
    margin: 0 auto;

    .form-group__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form-group {
      width: calc(50% - 10px);

      &:nth-child(5) {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    margin: 0 0 10rem;
    .main-holder{
      &:before {
        left: -43rem;
        top: 5rem;
      }
    }
    .form {

      .btn-hold {
        .button {
          min-width: 180px;
        }
      }

      .form-group {
        width: 100%;

        &:last-of-type {
          width: 100%;
        }
      }
    }
  }
}

.hireUs-form {
  margin: 1rem 0 9rem;
  position: relative;
  z-index: 9;

  .main-holder {
    position: relative;

    &:before {
      position: absolute;
      top: -38rem;
      right: -25rem;
      content: '';
      width: 702px;
      height: 627px;
      background: url("#{$img-path}/decor-section__hireUs.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }
  }

  .form {
    max-width: 770px;
    margin: 0 auto;

    .form-group__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .form-group {
      width: calc(50% - 10px);

      &:nth-child(7) {
        width: 100%;
      }

      &:last-of-type, &:nth-last-child(-n+2) {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    margin: 0 0 10rem;

    .main-holder:before {
      top: -14rem;
      right: -39rem;
    }

    .form {

      .btn-hold {
        .button {
          min-width: 180px;
        }
      }

      .form-group {
        width: 100%;

        &:last-of-type {
          width: 100%;
        }
      }
    }
  }
}


.rtl-page .contactus-banner:before {
  transform: scaleX(-1);
}

@media only screen and (max-width: 1200px) {
  .page-notfound-content {
    flex-direction: column;
    gap: 3rem;
  }

  .contactus-details {
    .items {
      .item {
        flex-direction: column;
        align-items: center;

        .text {
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 768px) {

  .error__page-content {
    padding: 0 0 30rem;

    h1 {
      font-size: 3rem;
      margin-bottom: 2.5rem;
    }
  }

  .page-notfound-content {
    padding: 16rem 0;

    h1 {
      font-size: 3rem;
    }
  }

  .contactus-details {
    padding: 1rem 0 4rem;

    .items {
      flex-direction: column;

      .item {
        flex-direction: row;
        padding: 2rem;
        align-items: flex-start;

        .text {
          text-align: start;
        }
      }
    }
  }

  .contactus-banner {
    &:before {
      background: #111D25 url(#{$img-path}/bg-contactus-banner-sm.jpg) 50% 50% / cover;
    }

    .main-holder {
      min-height: 47.6rem;
    }
  }
}

.tradingview-widget-container {
  background: var(--fifth-bg);
}

[class*=content-section] {
  position: relative;
  padding: 10rem 0;
}

.section-heading {
  margin-bottom: 6rem;

  *:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  [class*=content-section] {
    padding: 4rem 0;
  }
  .section-heading {
    margin-bottom: 3rem;
  }
}

.content-section01 {
  background: var(--secondary-bg);

  .section-heading {
    max-width: 117rem;
    margin-left: auto;
    margin-right: auto;
  }

  .items {
    display: flex;
    gap: 3rem;

    .item {
      display: flex;
      position: relative;
      padding: 3rem;
      flex: 1;
      gap: 3rem;
      margin: 0;

      *[class*=icon-] {
        display: inline-block;
        vertical-align: top;
        font-size: 4.4rem;
        line-height: 8rem;
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        color: var(--tertiary-color);
        text-align: center;
        margin: .5rem 0;
      }

      .text {
        flex: 1;

        h2 {
          margin-bottom: 1rem;
        }

        *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .content-section01 {
    .item {
      flex-direction: column;
      align-items: center;

      .text {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .content-section01 {
    .items {
      flex-wrap: wrap;
      gap: 2rem;

      .item {
        padding: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .content-section01 {
    .items {
      flex-direction: column;

      .item {
        flex-direction: row;
        padding: 2rem;
        align-items: flex-start;

        *[class*=icon-] {
          margin: 0;
        }

        .text {
          text-align: start;
        }
      }
    }
  }
}


.payment {
  background: #f6f6f6;
  padding: 24rem 0 14rem;
  position: relative;

  &:after {
    position: absolute;
    top: -1px;
    left: 0;
    content: '';
    width: 284px;
    height: 224px;
    background: url("#{$img-path}/decor-section10-1.png") no-repeat 0 0;
    background-size: 100%;
  }

  &:before {
    position: absolute;
    bottom: 0;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section10-2.png") no-repeat 0 0;
    background-size: 100%;
  }

  .items {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .txt-h {
      width: 370px;
      color: #303030;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      position: relative;
      z-index: 1;

      .num {
        position: relative;
        max-width: 205px;
        margin-bottom: 90px;

        sup {
          position: relative;
          top: -32px;
        }

        &:before {
          position: absolute;
          top: -8rem;
          right: 0;
          content: '';
          width: 126px;
          height: 133px;
          background: url("#{$img-path}/decor-section10-3.png") no-repeat 0 0;
          background-size: 100%;
        }

        &:first-of-type {
          padding-bottom: 25px;
          margin-bottom: 110px;

          &:after {
            background: #48D0A4;
            width: 165px;
            bottom: 0;
            height: 1px;
            position: absolute;
            left: 0;
            content: '';
          }
        }

        p {
          color: #289D3B;
          font-size: 75px;
          font-style: normal;
          font-weight: 700;
          line-height: 52px;
        }
      }
    }

    .img-h {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 1600px) {
    padding: 10rem 0;
    .img-h {
      img {
        max-width: 67%;
      }
    }
    .items {
      .txt-h {
        .num {
          max-width: 165px;
          margin-bottom: 50px;

          &:before {
            top: -6rem;
            width: 95px;
            height: 100px;
          }

          &:first-of-type {
            padding-bottom: 18px;
            margin-bottom: 80px;
          }

          p {
            font-size: 60px;
            line-height: 38px;

            sup {
              top: -40px;
            }
          }
        }
      }
    }

  }

  @media only screen and (max-width: 992px) {
    padding-top: 0;
    padding-bottom: 7.5rem;

    &:after {
      width: 91px;
      height: 80px;
    }
    &:before {
      display: none;
    }
    .items {
      justify-content: center;
      flex-direction: column;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        width: 100%;

        img {
          max-width: 100%;
          width: 100%;
        }
      }

      .txt-h {
        width: 100%;
        font-size: 18px;
        line-height: 32px;
        padding: 6rem 1.5rem 0;

        .num {
          margin: 0 auto 50px;
          p{
            line-height: 34px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    background: linear-gradient(0deg, rgba(245, 245, 245, 0.00) 41.54%, #F5F5F5 49.22%);
  }
}

.section11 {
  padding: 16rem 0 14.5rem;

  h2, .h2 {
    color: #303030;
  }

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .img-h {
      position: absolute;
      right: -32rem;
      top: -21%;
    }

    .txt-h {
      color: #303030;
      font-size: 22px;
      z-index: 1;
      margin-top: -27px;

      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 50px;
        line-height: 30px;

        li {
          margin-bottom: 23px;
          padding-left: 7.3rem;

          &:before {
            position: absolute;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            left: 0;
            background: #289D3B;
            content: '';
          }

          &:nth-child(3):before {
            background: #F6D03F;
          }

          &:nth-child(4):before {
            background: #5C5BA2;
          }

          &:nth-child(5):before {
            background: rgba(36, 126, 99, 0.74);
          }

          &:nth-child(6):before {
            background: #247E63;
          }

          &:nth-child(7):before {
            background: #186E4D;
          }

          &:nth-child(8):before {
            background: #FFE788;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 5.5rem 0 3.5rem;
    .items {
      display: block;

      .img-h {
        position: static;
        margin-left: -13.5rem;
        margin-right: -13rem;
        margin-bottom: 2rem;
      }

      .txt-h {
        margin-bottom: 50px;

        .btn-hold {
          display: flex;
          justify-content: center;

          .button2 {
            min-width: 220px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 0;
    h2 {
      font-size: 30px;
      line-height: 42px;
      text-align: center;
    }
    .items {
      margin: 0 -1.5rem;

      /*   .img-h {
           img {
             width: 100%;
           }
         }*/

      .txt-h {
        font-size: 18px;
        line-height: 23px;
        padding: 0 1.5rem;
        ul{
          line-height: 23px;
          margin-bottom: 4rem;
        }
        ul li {
          padding-left: 4.4rem;
          margin-bottom: 24px;

          &:before {
            width: 20px;
            height: 20px;
            left: 15px;
          }
        }
      }
    }
  }
}

.section12 {
  text-align: center;
  padding: 6rem 0;
  position: relative;
  z-index: 1;

  &.justify-c {
    .items {
      justify-content: space-between;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: url("#{$img-path}/decor-section12.png") no-repeat 50% 100%;
  }

  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding-top: 55px;
    position: relative;
    z-index: 1;
    gap: 30px;

    .item {
      width: calc(33.33% - 20px);
      margin-bottom: 40px;
      padding: 57px 24px 45px;
      border-radius: 25px;
      background: #FAF3FF;
      position: relative;
      text-align: center;
      font-size: 18px;
      line-height: 20px;

      &:after {
        content: "\e91d";
        font-family: 'icomoon';
        position: absolute;
        right: 30px;
        top: -15px;
        font-size: 5rem;
        color: #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 11px;
        border: 1px solid #D9D9D9;
        background: var(--white-color);
        width: 73px;
        height: 69px;
      }

      .button {
        border-radius: 25px;
        background: #A977E5;
        min-width: 196px;
        padding: 16px;
        font-size: 18px;
        &:hover {
          border-color: #A977E5;
          color: #A977E5;
          background: var(--white-color);
        }
      }

      &.blue {
        background: #E6F7FF;

        .button {
          background: #42A5FF;

          &:hover {
            border-color: #42A5FF;
            color: #42A5FF;
            background: var(--white-color);
          }
        }

        &:after {
          content: "\e920";
        }
      }

      &.orange {
        background: #FFF4EC;

        .button {
          background: #FB7444;

          &:hover {
            border-color: #FB7444;
            color: #FB7444;
            background: var(--white-color);
          }
        }

        &:after {
          content: "\e92d";
        }
      }

      &.azzur {
        background: #E9FCFA;

        .button {
          background: #48D0D0;

          &:hover {
            border-color: #48D0D0;
            color: #48D0D0;
            background: var(--white-color);
          }
        }

        &:after {
          content: "\e91f";
        }
      }

      &.yellow {
        background: #FFF8DC;

        .button {
          background: #FBB62B;

          &:hover {
            border-color: #FBB62B;
            color: #FBB62B;
            background: var(--white-color);
          }
        }

        &:after {
          content: "\e91e";
        }
      }

      h3 {
        color: #000;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 15px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding-top: 7rem;
    .items {
      .item {
        width: calc(50% - 20px);
      }
    }
  }
  @media only screen and (max-width: 570px) {
    &:after {
      width: 95%;
      height: 90%;
      background: url(#{$img-path}/decor-section12.png) no-repeat 50% 85%;
      background-size: 100%;
    }

    .items {
      padding-top: 25px;
      gap: 0;


      .item {
        width: 100%;
        margin-bottom: 35px;
        padding: 20px 15px 20px;
        font-size: 16px;
        line-height: 18px;

        &:after {
          font-size: 3rem;
          width: 46px;
          height: 43px;

        }

        .button {
          border-radius: 25px;
          background: #A977E5;
          min-width: 196px;
          padding: 16px;

          &:hover {
            border-color: #A977E5;
            color: #A977E5;
            background: var(--white-color);
          }
        }

        h3 {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
.rtl-page .section12 {
  .items .item::after {
    right: auto;
    left: 3rem;
  }
}

.section13 {
  position: relative;

  h2 span {
    color: #289D3B;
  }

  .items {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .img-h {
      position: absolute;
      top: 14rem;
      left: 0;
    }

    .txt-h {
      padding: 26rem 0 43rem;
      font-size: 22px;

      h2{
        margin-bottom: 3.5rem;
      }

      ol {
        margin-bottom: 50px;

        li {
          margin-bottom: 10px;
        }
      }

      .button {
        min-width: 180px;
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .items {
      .img-h {
        max-width: 885px;
      }

      .txt-h {
        padding: 15rem 0;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column-reverse;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        max-width: 100%;
        min-width: 1200px;
      }

      .txt-h {
        padding: 2rem 1.5rem 5rem;
        padding-inline-start: 5rem;
        width: 100%;
        .btn-hold {
          margin-inline-start: -3.5rem;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .items {
      .txt-h {
        font-size: 23px;
        h2{
          margin-bottom: 3rem;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .items {
      .img-h {
        min-width: auto;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
    .txt-h {
      font-size: 20px;

      .btn-hold {
        display: flex;
        justify-content: center;

        .button {
          min-width: 220px;
        }
      }
    }
  }
}

.section14 {
  text-align: center;
  padding: 5rem 0 11rem;

  .main-holder {
    position: relative;

    h2 {
      margin-bottom: 7rem;
    }

    &:after {
      position: absolute;
      top: -70px;
      left: 0;
      content: '';
      width: 605px;
      height: 588px;
      background: url("#{$img-path}/decor-section14.png") no-repeat 0 0;
      background-size: 100%;
      z-index: -1;
    }
  }

  .status {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 30px 40px 0;

    .status-child {
      position: relative;
      padding-left: 25px;
      color: #303030;
      font-size: 22px;
      font-weight: 400;
      line-height: 16px;

      &.comp, &.prog {
        &:after {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #289D3B;
          left: 7px;
          top: 0;
          position: absolute;
        }
      }

      &.prog:after {
        background: #6A9CF7;
      }
    }
  }

  .items {
    display: flex;
    justify-content: flex-start;
    gap: 28px;

    .item {
      border-radius: 160.687px 90px 90px 6.827px;
      background: #F1F1F1;
      width: 252px;
      height: 63px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;

      &.comp, &.prog {
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #289D3B;
          right: 7px;
          top: 2px;
          position: absolute;
        }
      }

      &.prog:after {
        background: #6A9CF7;
      }
    }
  }

  .items:nth-child(2) {
    justify-content: flex-end;
  }

  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0;

      .item {
        width: 290px;
        height: 74px;
        margin-bottom: 32px;
      }
    }
    .status {
      justify-content: center;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 6rem 0 10rem;

    .items .item.comp:after, .items .item.prog:after {
      right: 10px;
    }
    .main-holder h2 {
      margin-bottom: 3rem;
    }
    .items .item img {
      transform: scale(1.1);
    }
    .status {
      padding: 20px 0 0;
      gap: 4px;

      .status-child {
        padding-left: 25px;
        font-size: 16px;

        &.comp, &.prog {
          &:after {
            width: 11px;
            height: 11px;
            left: 7px;
            top: 0;
          }
        }
      }
    }
  }
}


.section15 {
  position: relative;

  .items {
    display: flex;
    align-items: center;

    .img-h {
      position: absolute;
      top: 0;
      right: 0;
    }

    .txt-h {
      padding: 33rem 0 37rem;
      text-align: center;

      .btn-hold {
        margin-top: 50px;
      }
    }
  }

  @media only screen and (max-width: 1650px) {
    .items {
      .img-h {
        max-width: 885px;
      }

      .txt-h {
        padding: 15rem 0;
      }
    }
  }
  @media only screen and (max-width: 1225px) {
    .items {
      .img-h {
        max-width: 600px;
      }

      .txt-h {
        padding: 12rem 0;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        max-width: 100%;
        min-width: 1200px;
      }

      .txt-h {
        padding: 2rem 2rem 0;
        width: 100%;
        .btn-hold {
          margin-top: 4rem;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .items {
      .img-h {
        min-width: auto;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
    .txt-h {
      .btn-hold {
        display: flex;
        justify-content: center;
      }
    }
  }
}


.section16 {
  text-align: center;
  position: relative;
  padding-top: 90px;
  z-index: 4;

  &:after {
    position: absolute;
    bottom: 2rem;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(#{$img-path}/decor-section16.png) no-repeat 0 100%;
    z-index: -1;
  }

  .main-holder {
    position: relative;

    &:before {
      position: absolute;
      top: -90px;
      right: 0;
      content: "";
      width: 767px;
      height: 269px;
      background: url(#{$img-path}/decor2-section16.png) no-repeat 0 0;
      background-size: 100%;
    }
  }

  .txt-h {
    max-width: 890px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 32px;
    position: relative;
    z-index: 1;

    .btn-hold{
      margin-top: 4rem;
      margin-bottom: 3rem;
    }
    .button-play {
      background: #D9D9D9;
    }

    .addition-txt{
      font-size: 1.8rem;
      line-height: 3.2rem;
      color: rgba(48, 48, 48, 0.65);
    }
  }
  .img-h{
    position: relative;
    margin: -2rem -.5rem;
  }

  @media only screen and (max-width: 480px) {
    padding-top: 60px;
    h1{
      margin-left: 2.2rem;
      margin-right: 2.2rem;
    }
    &:after {
      bottom: 3rem;
      background: url(#{$img-path}/decor-section16-sm.png) no-repeat 0 100%;
    }

    .main-holder {
      position: relative;

      &:before {
        position: absolute;
        top: -90px;
        right: 0;
        content: "";
        width: 767px;
        height: 269px;
        background: url(#{$img-path}/decor2-section16.png) no-repeat 0 0;
        background-size: 100%;
      }
    }
    .txt-h {
      margin-bottom: 0;
      .btn-hold{
        margin-top: 4.5rem;
        margin-bottom: 2rem;
      }

    }
    .img-h {
      margin: -2rem -1.5rem -3rem;

      img {
        width: 100%;
      }
    }
  }
}


.section17 {
  text-align: center;
  position: relative;
  padding-top: 135px;
  margin-bottom: 2rem;
  background: #000;
  color: #000;
  font-size: 20px;
  line-height: 32px;

  h2 {
    color: var(--white-color);
    margin-bottom: 100px;
  }

  &:after {
    position: absolute;
    bottom: -200px;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: url(#{$img-path}/decor-section17.png) no-repeat 50% 100%;
    z-index: 2;
  }


  .items {
    display: flex;
    margin-bottom: 80px;
    position: relative;
    z-index: 5;
    gap: 15px;
  }

  .main-holder {
    position: relative;
    z-index: 3;

    &:before {
      position: absolute;
      top: -135px;
      left: -25%;
      content: "";
      width: 839px;
      height: 680px;
      background: url(#{$img-path}/decor-section17-2.png) no-repeat 0 0;
      background-size: 100%;
    }
  }

  .btn-hold {
    margin-top: 50px;

    .button {
      width: 200px;
      padding: 1rem 1.5rem;
    }
  }


  @media only screen and (max-width: 768px) {
    .items {
      flex-direction: column;
      gap: 0;
      margin-bottom: 7rem;

      .item:first-of-type {
        margin-bottom: 10px;
      }
    }
    .btn-hold {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: 480px) {
    padding-top: 132px;
    h2 {
      margin-bottom: 70px;
    }
    &:after {
      bottom: -30px;
    }
    .main-holder:before {
      top: -132px;
      left: -6%;
      width: 325px;
      height: 168px;
    }
  }
}


.section18 {
  position: relative;
  z-index: 9;

  .items {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .img-h {
      position: absolute;
      top: 45px;
      left: 0;
    }

    .txt-h {
      padding: 33rem 0 28rem;
      max-width: 570px;
      color: #303030;
      font-size: 20px;
      line-height: 32px;
      z-index: 10;
      position: relative;

      &:before {
        position: absolute;
        bottom: 4px;
        right: -103px;
        content: "";
        width: 761px;
        height: 329px;
        background: url(#{$img-path}/decor-section18.png) no-repeat 0 0;
        background-size: 100%;
      }

      .btn-hold {
        margin-top: 50px;
      }
    }
  }

  @media only screen and (max-width: 1750px) {
    .items {
      .img-h {
        max-width: 675px;
      }

      .txt-h {
        padding: 15rem 0 25rem;

        &:before {
          bottom: 65px;
          right: 40px;
          width: 563px;
          height: 243px;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .items {
      .img-h {
        max-width: 500px;
      }

      .txt-h {
        padding: 8rem 0 20rem;
        max-width: 470px;

        &:before {
          bottom: 65px;
          right: 40px;
          width: 451px;
          height: 197px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column-reverse;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        max-width: 100%;
        min-width: 1200px;
        padding-top: 8rem;
      }

      .txt-h {
        padding: 2rem 1.5rem 24rem;
        width: 100%;
        text-align: center;
        max-width: 100%;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .items {
      .img-h {
        min-width: auto;
        width: 100%;
        padding-top: 4rem;

        img {
          width: 100%;
        }
      }

      .txt-h {
        padding: 5rem 1.5rem 18rem;

        &:before {
          right: 0;
          left: 0;
          margin: 0 auto;
          width: 320px;
          height: 142px;
        }

        .btn-hold {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    }
  }
}


.section19 {
  position: relative;

  .items {
    display: flex;
    align-items: center;

    .img-h {
      position: absolute;
      top: 9rem;
      right: 0;
    }

    .txt-h {
      padding: 23rem 0 62.5rem;
      max-width: 480px;
      color: #303030;
      font-size: 20px;
      line-height: 32px;
      position: relative;
      z-index: 10;

      .btn-hold {
        margin-top: 50px;
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    .items {
      .img-h {
        max-width: 885px;
      }

      .txt-h {
        padding: 15rem 0 23rem;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        max-width: 100%;
        min-width: 1200px;
      }

      .txt-h {
        padding: 0 1.5rem 5rem;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .items {
      padding-bottom: 0;

      .img-h {
        min-width: auto;
        width: 100%;

        img {
          width: 100%;
        }
      }

      .txt-h {
        padding-bottom: 0;
        .btn-hold {
          display: flex;
          justify-content: center;
          margin-top: 40px;
        }
      }
    }
  }
}

.section20 {
  position: relative;
  padding-bottom: 18.5rem;

  h2 {
    width: 340px;
    margin-left: auto;
    margin-bottom: 60px;
  }

  .items {
    border-top: 1px solid #289d3b80;
    padding: 0;
    display: flex;
    justify-content: space-between;

    .img-h {
      width: 260px;
      padding: 50px 15px 0;
      position: relative;

      &:before {
        position: absolute;
        bottom: -15rem;
        right: -32rem;
        content: "";
        width: 636px;
        height: 574px;
        background: url(#{$img-path}/decor-section20.png) no-repeat 0 0;
        background-size: 100%;
      }

      .btn-hold {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 15px;
        bottom: 6rem;
      }
    }

    .txt-h {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      ul {
        list-style-type: none;
        color: #303030;
        font-size: 22px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        width: 430px;

        &:last-of-type {
          width: 310px;
          margin: 0 55px;

          li {
            &:last-of-type {
              border-bottom: 0;
            }
          }
        }

        li {
          padding: 15px 0;
          border-bottom: 1px solid #30303026;
        }
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    .items {
      .img-h {
        width: 260px;
        padding: 50px 15px 0;

        &:before {
          bottom: -15rem;
          right: -32rem;
          width: 636px;
          height: 574px;
        }

        .btn-hold {
          bottom: 15px;
        }
      }

      .txt-h {
        ul {
          font-size: 18px;
          line-height: 22px;
          width: 365px;

          &:last-of-type {
            width: 250px;
            margin: 0 40px;
          }

          li {
            padding: 17px 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column;

      .img-h {
        width: 260px;
        margin: 0 auto;

        .btn-hold {
          position: static;
          margin-top: 80px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 6rem;
    padding-bottom: 5.5rem;
    h2 {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
    .items {

      .txt-h {
        ul {
          width: 100%;
          margin: 0;

          &:last-of-type {
            width: 100%;
            margin: 0;
          }

          li {
            border-bottom: 1px solid #30303026 !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .items {
      .img-h {
        padding: 50px 15px 0;

        img {
          max-width: 170px;
          margin: 0 auto;
          display: block;
        }

        .btn-hold {
          margin-top: 35px;
        }

        &:before {
          bottom: -13rem;
          right: -17rem;
          width: 405px;
          height: 364px;
        }
      }

      .txt-h {
        ul {
          li {
            padding: 5px 0;
          }
        }
      }
    }
  }
}


.section21 {
  position: relative;
  margin-top: 10rem;

  .items {
    display: flex;
    align-items: center;

    .img-h {
      position: absolute;
      top: -2rem;
      right: 19rem;
    }

    .txt-h {
      padding: 7rem 0 48rem;
      max-width: 620px;

      .btn-hold {
        margin-top: 50px;

        .button {
          min-width: 190px;
        }
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .items {
      .img-h {
        right: 0;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .items {
      .img-h {
        max-width: 885px;
        top: 17rem;
      }

      .txt-h {
        padding: 15rem 0 45rem;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .items {
      flex-direction: column;
      margin: 0 -1.5rem;

      .img-h {
        position: static;
        max-width: 100%;
        min-width: 1200px;
      }

      .txt-h {
        padding: 0 1.5rem;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    margin-top: 25px;
    .items {

      &.padding-sm {
        padding-bottom: 0;
      }

      .img-h {
        min-width: auto;
        width: 100%;

        img {
          width: 100%;
        }
      }

      .txt-h {
        .btn-hold {
          display: flex;
          justify-content: center;
          margin-top: 40px;

          .button {
            padding: 1rem 3rem;
            min-width: 220px;
          }
        }
      }
    }
  }
}

.section22 {
  text-align: center;
  position: relative;
  padding-top: 90px;
  background: url(#{$img-path}/bg-section22.png) no-repeat 0 100%;
  background-size: cover;
  color: var(--white-color);


  .txt-h {
    font-size: 20px;
    line-height: 32px;
    position: relative;
    z-index: 1;
    padding-bottom: 3.5rem;

    p {
      max-width: 755px;
      margin: 0 auto;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.65);
    }

    .btn-hold {
      margin: 4rem 0 3rem;

      .button-play .txt {
        padding: 18px 25px;
      }

      .button-play .icon {
        padding: 18px 2px 20px 0;

        .icon-play {
          padding: 0 18px;
        }
      }
    }
    .addition-txt{
      font-size: 1.8rem;
      line-height: 3.2rem;
    }

    h1 {
      color: var(--white-color);
    }

    .button-play {
      background: #D9D9D9;
      // border: 3px solid #5C5BA2;
      box-shadow: 0 0 0 3px #5C5BA2;
      border-radius: 11px;

      &:hover .icon {
        color: #5C5BA2;
      }
    }

  }

  .button-play:hover .icon {
    margin-left: -57px;
  }


  @media only screen and (max-width: 480px) {
    padding-top: 60px;
    background: url(#{$img-path}/bg-section22-sm.png) no-repeat 0 100%;
    background-size: cover;
    padding-bottom: 0;
    .txt-h {
      padding: 0 1.5rem 2.5rem;
      margin-bottom: 0;

      .btn-hold {
        margin: 4rem 0 3rem;

        .button-play .txt {
          font-size: 16px !important;
        }
      }
    }
    .img-h {
      margin: 0;

      img {
        width: 100%;
      }
    }
  }
}


.section24 {
  position: relative;
  padding: 19rem 0 10.5rem;
  background: url(#{$img-path}/bg-section24.png) no-repeat 0 100%;
  background-size: cover;
  color: var(--white-color);
  z-index: 2;

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    content: "";
    width: 211px;
    height: 185px;
    background: url(#{$img-path}/decor-section24.png) no-repeat 0 0;
    background-size: 100%;
  }

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 211px;
    height: 185px;
    background: url(#{$img-path}/decor-section24-2.png) no-repeat 0 0;
    background-size: 100%;
  }

  .items {
    display: flex;
    justify-content: space-between;
    position: relative;

    .item {
      .btn-hold {
        margin-top: 55px;

        .button {
          min-width: 220px;
        }
      }

      .item-child {
        border-radius: 22px;
        border: 1px solid #899AFF;
        background: #000;
        margin-bottom: 22px;
        display: flex;
        padding: 20px 25px;
        justify-content: center;
        flex-direction: column;
        color: var(--white-color);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        width: 370px;

        p {
          margin-bottom: 0;
        }

        h6 {
          font-weight: 700;
          color: var(--white-color);
        }
      }
    }

    .img-h {
      position: absolute;
      left: -29rem;
      bottom: -22rem;

      picture {
        img {
          max-width: fit-content;
        }
      }
    }
  }

  h2 {
    color: var(--white-color);
  }

  @media only screen and (max-width: 1200px) {
    .items .img-h {
      left: -45rem;
    }
  }

  @media only screen and (max-width: 991px) {
    &:before {
      display: none;
    }
    .items {
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;

      .txt-h {
        margin-bottom: 50px;
      }

      .img-h {
        position: static;
        padding-top: 35px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 20.5rem 0 0;
    background: url(#{$img-path}/bg-section24-sm.jpg) no-repeat 0 100%;
    background-size: cover;
    &:after {
      width: 91px;
      height: 80px;
    }
    .items .item .btn-hold .button {
      min-width: 220px;
      font-size: 16px;
      padding: 1rem 2rem;
    }

    .items .item .btn-hold {
      margin-top: 40px;
    }

    .items .item .item-child {
      width: 100%;
      margin-bottom: 16px;
      padding: 19px 25px;
    }
    .items .img-h {
      width: 100%;
      padding: 0;
      margin-top: -6.5rem;
      margin-bottom: -5rem;

      picture {
        display: block;
        margin: 0 -15px;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.section25 {
  position: relative;
  padding: 15rem 0 10rem;
  background: url(#{$img-path}/bg-section25.png) no-repeat 0 100%;
  background-size: cover;
  min-height: 117rem;

  .items {
    display: flex;
    justify-content: space-between;
    position: relative;

    .txt-h {
      text-align: right;

      .btn-hold {
        margin-top: 50px;
      }
    }

    .img-h {
      position: absolute;
      left: 36rem;
      top: 92%;

      picture {
        img {
          max-width: fit-content;
        }
      }
    }

    .ul-list {
      display: flex;
      gap: 70px;
      padding-top: 12rem;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        margin-bottom: 12px;

        &:after {
          content: '';
          background: #5C5BA2;
          width: 90%;
          bottom: -6px;
          left: 0;
          height: 1px;
          position: absolute;
        }
      }

      span {
        display: block;
        border-radius: 20px;
        background: linear-gradient(90deg, #706E94 37.16%, #D5D5D5 100%);
        width: 236px;
        height: 42px;
        padding: 9px 28px;
        align-items: center;
        color: var(--white-color);
        font-size: 22px;
        font-weight: 400;
        line-height: 27px;
      }
    }
  }

  h2 {
    color: #303030;
  }

  @media only screen and (max-width: 1920px) {
    min-height: 102.5rem;
  }

  @media only screen and (max-width: 991px) {
    min-height: auto;
    padding: 10rem 0 17rem;
    .items {
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;

      ul span {
        text-align: initial;
        width: 100%;
      }

      .ul-list {
        gap: 0;
        padding-top: 0;
        flex-direction: column;
        text-align: inherit;
        max-width: 400px;
        margin: 0 auto;
      }

      .item {
        order: 1;
        width: 100%;
      }

      .txt-h {
        margin-bottom: 50px;
        order: 0;
        text-align: center;
      }

      .img-h {
        position: static;
        padding-top: 35px;
        order: 2;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 6rem 0 0;
    margin-bottom: 6.5rem;
    background: url(#{$img-path}/bg-section25-sm.jpg) no-repeat 0 100%;
    background-size: cover;
    .items .item .btn-hold .button {
      min-width: 180px;
      padding: 1rem 2rem;
    }

    .items .item .btn-hold {
      margin-top: 40px;
    }

    .items .item .item-child {
      width: 100%;
      margin-bottom: 16px;
    }
    .items .img-h {
      width: 100%;
      margin-top: 19.5rem;
      margin-bottom: -6rem;

      picture {
        display: block;
        margin: 0 -15px;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.section26 {
  position: relative;
  padding: 7.5rem 0 18rem;

  h2 {
    text-align: center;
    margin-bottom: 12rem;
  }

  &:before {
    position: absolute;
    bottom: -7rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    width: 539px;
    height: 529px;
    background: url(#{$img-path}/decor-section26.png) no-repeat 0 0;
    background-size: 100%;
  }

  .btn-hold {
    display: flex;
    justify-content: center;
  }

  .ul-list {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0 auto 10rem;
    gap: 30px;

    li {
      position: relative;
      padding-left: 115px;
      color: #000;
      font-size: 20px;
      font-weight: 300;
      line-height: 32px;
      width: 33.33%;

      strong {
        color: #303030;
        font-size: 29px;
        font-weight: 700;
        line-height: 40px;
        position: relative;
        display: flex;
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:after {
          border-bottom: 1px solid rgba(48, 48, 48, 0.15);
          width: 50%;
          display: flex;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
        }

      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 90px;
        height: 90px;
        background: #48d0a480 !important;
        border-radius: 50%;
      }

      &:after {
        position: absolute;
        top: 25px;
        left: 25px;
        content: "";
        width: 40px;
        height: 40px;
        background: #48d0a4;
        border-radius: 50%;
      }

      &:nth-child(2) {
        &:before {
          background: #F6D03F80 !important;
        }

        &:after {
          background: #F6D03F !important;
        }
      }

      &:nth-child(3) {
        &:before {
          background: #5C5BA280 !important;
        }

        &:after {
          background: #5C5BA2 !important;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0 0 10rem;
    .ul-list {
      flex-direction: column;
      align-items: center;

      li {
        position: relative;
        padding-left: 115px;
        color: #000;
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
        max-width: 480px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 6rem 0 5rem;
    &:before {
      width: 407px;
      height: 444px;
    }
    h2 {
      margin-bottom: 3rem;
      padding: 0 5px;
    }
    .button {
      min-width: 180px;
      padding: 1rem 2rem;
    }
    .ul-list {
      gap: 10px;
      margin: 0 auto 2rem;

      li {
        padding-left: 90px;

        &:before {
          width: 72px;
          height: 72px;
        }

        &:after {
          top: 21px;
          left: 22px;
          width: 30px;
          height: 30px;
        }

        strong {
          font-size: 25px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.section27 {
  position: relative;
  padding: 15rem 0 10rem;
  background: url(#{$img-path}/bg-section27.jpg) no-repeat 50% 100%;
  background-size: cover;
  border-radius: 33rem 33rem 0 0;
  margin-top: 12.5rem;

  .button {
    min-width: 180px;
  }

  .items {
    display: flex;
    justify-content: space-between;

    .img-h {
      position: relative;
      top: -21rem;
      margin: 0 1.5rem -18rem;
    }

    .txt-h {
      min-width: 57rem;
      text-align: center;
      h2{font-weight: 300;}
      .btn-hold{
        margin-top: 4.5rem;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    border-radius: 25rem 25rem 0 0;

  }
  @media only screen and (max-width: 991px) {
    border-radius: 0;
    margin-top: 0;
    padding: 10rem 0 10rem;
    .items {
      flex-direction: column;
      text-align: center;

      .img-h {
        top: 0;
        margin-bottom: 65px;

      }
      .txt-h {
        min-width: auto;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    background: url(#{$img-path}/bg-section27-sm.jpg) no-repeat 0 100%;
    background-size: cover;
    margin-top: 8rem;
    padding: 0 0 10rem;
    .items {
      .img-h{
        margin: -6rem 0 9rem;
        picture {
          display: block;
          margin: 0 -15px;
  
          img {
            width: 100%;
          }
        }
      }
      .txt-h{
        h2{
          font-weight: normal;
        }
        .btn-hold{
          margin-top: 3.5rem;
          .button{
            min-width: 22rem;
          }
        }
      }
    }
  }
}

.newsletter-form {
  background: linear-gradient(270deg, #A0C9C9 15.72%, #48D0D0 78.47%);
  // height: 611px;
  padding: 8.5rem 0;
  position: relative;
  color: var(--white-color);
  border-radius: 315px 0 0 0;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-newsletter-03.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  &:after {
    position: absolute;
    bottom: -185px;
    left: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-newsletter-04.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 3;
  }

  .form-check {
    margin: 0;
    // padding-left: 40px;
    + .text-error{
      // padding-left: 40px;
    }
  }


  form{
    ::-webkit-input-placeholder {
      color: var(--white-color);
    }
    ::-moz-placeholder{
      color: var(--white-color);
    }
    ::-ms-input-placeholder{color: var(--white-color);}
  }

  .txt-h{
    margin: 0 9rem;
  }
  h2 {
    color: var(--white-color);
    margin-bottom: 4rem;
    z-index: 100;
    position: relative;
  }

  .main-holder {
    height: 100%;
  }

  .items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    text-align: center;
  }


  .img-h {
    position: absolute;
    // top: -62px;
    bottom: 0;
    left: 87px;
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    width: 100%;
    margin: 0 auto;

    &:before {
      position: absolute;
      bottom: -8rem;
      right: -32rem;
      content: '';
      width: 893px;
      height: 611px;
      background: url("#{$img-path}/decor-newsletter-02.png") no-repeat 0 0;
      background-size: 100%;
      z-index: -1;
    }

    &:after {
      position: absolute;
      bottom: -84px;
      left: -60px;
      content: '';
      width: 517px;
      height: 334px;
      background: url("#{$img-path}/decor-newsletter-01.png") no-repeat 0 0;
      background-size: 100%;
      z-index: -1;
    }
  }

  .form-group__list {
    flex: 1;
  }

  .form .form-control {
    border-color: var(--white-color);
    color: var(--white-color);
  }


  .form-group label, .form-group .form-label {
    color: var(--white-color);
  }

  .form-group label:after, .form-group .form-label:after {
    border-color: var(--white-color);
  }

  .form-group label:before, .form-group .form-label:before {
    background: var(--white-color);
  }

  .btn-hold{
    .button{
      min-width: 205px;
    }
  }
  .re-captcha-h {
    display: block;
  }

  @media only screen and (max-width: 1700px) {
    .txt-h{
      margin: 0 3rem;
    }
    .form {
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;

      &:before {
        display: none;
      }

      &:after {
        bottom: -77px;
        left: -100px;
        width: 447px;
        height: 291px;
      }
    }
  }

  @media only screen and (max-width: 1500px) {
    .img-h {
      left: 136px;
      top: auto;
      bottom: 0;
      max-width: 520px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .img-h {
      max-width: 400px;
    }
  }
  @media only screen and (max-width: 991px) {
    height: auto;
    padding: 60px 15px 75px;
    .form:after {
      bottom: -64px;
    }
    .items {
      justify-content: center;
    }
    
    .img-h {
      position: static;
      margin-bottom: 3rem;
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 90px 0 0 0;
    &:before, &:after {
      width: 91px;
      height: 80px;
    }
    &:after {
      bottom: -80px;
    }
    .form .btn-hold {
      margin-top: -5px;

      .button {
        min-width: 205px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    h2 {
      padding: 0 5px;
      margin-bottom: 3rem;
    }
    .txt-h{
      margin: 0;
    }
    .img-h{
      margin: 0 -1.5rem 3rem;
    }
    .form{width: 290px;}
    .form:after {
      bottom: -76px;
      left: auto;
      right: -55px;
      width: 320px;
      height: 379px;
      background: url(#{$img-path}/decor-newsletter-01-sm.png) no-repeat 0 0;
    }
  }
}


.questions {
  &__holder {
    background-color: $background-color;
    background-image: url("#{$img-path}/questions-img.png"),
    url("#{$img-path}/questions-decor-2.png");
    background-position: center bottom, 85% 80%;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    overflow: hidden;
    line-height: 33px;

    .txt-h {
      max-width: 640px;
      margin: 0 auto;
      position: relative;
      z-index: 9;
    }

    .main-holder {
      position: relative;
      min-height: 925px;
      padding: 16rem 1.5rem;
      text-align: center;

      &:before {
        position: absolute;
        bottom: -10%;
        left: -40%;
        content: '';
        width: 908px;
        height: 945px;
        background: url("#{$img-path}/questions-decor-3.png") no-repeat 0 0;
        background-size: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        width: 788px;
        height: 339px;
        top: -45px;
        right: -259px;
        background: url("#{$img-path}/questions-decor.png") no-repeat 0 0;
        background-size: 100%;
      }
    }

    h2 {
      background: var(--Help, linear-gradient(91deg, #46CD98 35.11%, #159E84 44.04%, #2D6776 55.63%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 69px;
      font-style: normal;
      font-weight: 400;
      line-height: 75px;
      text-transform: capitalize;
    }

    .form {
      display: flex;
      border-radius: 11px;
      background: var(--Help-button, linear-gradient(90deg, #389C75 88.49%, #3B747C 98.19%));
      background: #289D3B;
      margin: 40px 0 35px;

      .form-group__list {
        flex: 1;

        .form-group {
          margin: 0;
        }
      }
      .btn-hold{
        margin-top: 10px;
      }
      .button {
        background: transparent;
        min-width: 85px;
        border: none;
        font-size: 0;
        position: relative;
        min-height: 38px;

        &:after {
          content: "\e91a";
          font-family: 'icomoon';
          position: absolute;
          font-size: 1.7rem;
          color: #fff;
          display: flex;
        }
      }

      .form-control {
        padding: 1.5rem 4rem;
        background: #fff;
        border: 1px solid #fff;
        font-weight: 500;
        border-radius: 10px;

        &:focus {
          border-color: #fff;
          background: #fff;
        }
      }

      .form-label {
        display: none;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    z-index: 1;
    position: relative;
  }

  &__item {
    border-radius: 20px;
    border: 1px solid #3B747C;
    color: #4ABC91;
    font-size: 17px;
    line-height: 32px;
    background: #010407;
    width: calc(33.33% - 15px);
    transition: all 0.3s ease;
    text-align: initial;
    padding: 4px 21px;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    height: 40px;

    &:hover {
      background: #48D0A4;
      color: #000;
      text-decoration: none;
    }
  }


  @media only screen and (max-width: 991px) {
    &__item {
      width: calc(50% - 15px);
    }
  }
  @media only screen and (max-width: 768px) {
    &__holder {
      .main-holder {
        min-height: 800px;
        padding: 6rem 1.5rem;
      }

      h2 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 27px;
      }

      .form .button {
        min-width: 60px;
      }

      .form .form-control {
        padding: 1.5rem 1rem;
      }
    }
    &__list {
      margin-top: 80px;
    }
    &__item {
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      padding: 4px 17px;
      margin-bottom: 17px;
    }
  }
  @media only screen and (max-width: 480px) {
    &__holder {
      background-image: url("#{$img-path}/questions-img-sm.png"),
      url("#{$img-path}/questions-decor-2-sm.png");
      background-position: center 80%, 100% 42%;

      .main-holder {

        &:before {
          bottom: 25%;
          left: -42%;
          width: 377px;
          height: 376px;
        }
      }
    }
  }
}

.help-center-list__component {
  padding: 13rem 0 15rem;
  position: relative;
  font-size: 20px;

  .news-list {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    top: -1px;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/help-center-decor-2.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  &:after {
    position: absolute;
    bottom: -1px;
    right: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/help-center-decor-1.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  .news-categories {
    margin-top: 6rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 6rem 0 8rem;
    p {
      padding: 0 1.5rem;
      line-height: 32px;
    }
    &:before {
      width: 91px;
      height: 85px;
    }
    &:after {
      width: 91px;
      height: 80px;
    }
    .news-categories {
      margin-top: 4rem;
    }
  }
}


.section60 {
  padding: 70px 0 0;
  background: #010407;
  position: relative;
  margin-bottom: 70px;

  &:before {
    position: absolute;
    bottom: 223px;
    left: 0;
    content: '';
    width: 211px;
    height: 185px;
    background: url("#{$img-path}/decor-section60-2.png") no-repeat 0 0;
    background-size: 100%;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    width: 766px;
    height: 176px;
    top: 0;
    right: 50%;
    margin-right: -725px;
    background: url("#{$img-path}/decor-section60-1.png") no-repeat 0 0 / cover;
    filter: blur(75px);
  }

  .txt-h {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    color: var(--white-color);
    max-width: 730px;
    margin: 0 auto 155px;
    position: relative;
    z-index: 1;

    .h1 {
      color: #4AB884;

      > strong {
        background: linear-gradient(91deg, #60CAF9 0%, #4AB884 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;

        &::selection {
          background-clip: initial;
          -webkit-text-fill-color: initial;
        }
      }
    }
  }

  .img-h {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 5;

    img {
      position: absolute;
      top: -136px;
    }
  }

  .decor_f {
    background: linear-gradient(90deg, #BFF0F0 0%, #EEFBFB 100%);
    height: 223px;
    position: relative;

    &:before {
      position: absolute;
      bottom: 89px;
      left: 0;
      content: '';
      width: 239px;
      height: 239px;
      background: url("#{$img-path}/decor-section60-4.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      width: 211px;
      height: 185px;
      background: url("#{$img-path}/decor-section60-3.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }
  }

  .decor_f2 {
    height: 89px;
    position: relative;
    margin-top: -89px;
    z-index: 2;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      width: 262px;
      height: 89.03px;
      background: url("#{$img-path}/decor-section60-5.png") no-repeat 0 0;
      background-size: 100%;
      z-index: 1;
    }

    &:after {
      background: #fff;
      width: calc(100% - 262px);
      height: 100%;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 60px 0 0;
    margin-bottom: 160px;
    &:before {
      bottom: 76px;
      width: 91px;
      height: 85px;
    }

    &:after {
      margin-right: -115px;
      filter: blur(78px);
      width: 300px;
    }


    .txt-h {
      margin: 0 auto 91px;
    }
    .img-h img {
      top: -55px;
      max-width: 290px;
      width: 100%;
    }

    .decor_f {
      height: 82px;

      &:before {
        bottom: 26px;
        width: 91px;
        height: 91px;
      }

      &:after {
        width: 91px;
        height: 85px;
      }
    }

    .decor_f2 {
      height: 26px;
      margin-top: -26px;

      &:before {
        width: 77px;
        height: 26px;
      }

      &:after {
        width: calc(100% - 77px);
      }
    }
  }
}


/*bootstrap fixes*/
@media only screen and (min-width: 575px) {
  .text-xs-start {
    text-align: start !important;
  }

  .text-xs-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 576px) {
  .text-sm-start {
    text-align: start !important;
  }

  .text-sm-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 768px) {
  .text-md-start {
    text-align: start !important;
  }

  .text-md-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 992px) {
  .text-lg-start {
    text-align: start !important;
  }

  .text-lg-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 1200px) {
  .text-xl-start {
    text-align: start !important;
  }

  .text-xl-end {
    text-align: end !important;
  }
}

/* offsets */
.rtl-page *[class*=offset-] {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .rtl-page .offset-sm-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-sm-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-sm-3 {
    margin-right: 25%
  }

  .rtl-page .offset-sm-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-sm-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-sm-6 {
    margin-right: 50%
  }

  .rtl-page .offset-sm-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-sm-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-sm-9 {
    margin-right: 75%
  }

  .rtl-page .offset-sm-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-sm-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 768px) {
  .rtl-page .offset-md-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-md-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-md-3 {
    margin-right: 25%
  }

  .rtl-page .offset-md-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-md-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-md-6 {
    margin-right: 50%
  }

  .rtl-page .offset-md-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-md-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-md-9 {
    margin-right: 75%
  }

  .rtl-page .offset-md-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-md-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 992px) {
  .rtl-page .offset-lg-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-lg-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-lg-3 {
    margin-right: 25%
  }

  .rtl-page .offset-lg-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-lg-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-lg-6 {
    margin-right: 50%
  }

  .rtl-page .offset-lg-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-lg-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-lg-9 {
    margin-right: 75%
  }

  .rtl-page .offset-lg-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-lg-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 1200px) {
  .rtl-page .offset-xl-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-xl-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-xl-3 {
    margin-right: 25%
  }

  .rtl-page .offset-xl-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-xl-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-xl-6 {
    margin-right: 50%
  }

  .rtl-page .offset-xl-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-xl-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-xl-9 {
    margin-right: 75%
  }

  .rtl-page .offset-xl-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-xl-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 1400px) {
  .rtl-page .offset-xxl-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-xxl-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-xxl-3 {
    margin-right: 25%
  }

  .rtl-page .offset-xxl-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-xxl-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-xxl-6 {
    margin-right: 50%
  }

  .rtl-page .offset-xxl-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-xxl-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-xxl-9 {
    margin-right: 75%
  }

  .rtl-page .offset-xxl-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-xxl-11 {
    margin-right: 91.666667%
  }
}

.cookies-h {
  border-radius: 20px 20px 0px 0px;
  background: var(--white-color);
  padding: 20px 25px 28px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);

  .social {
    gap: 3rem;

    a {
      width: 2rem;
      height: 3rem;
      font-size: 2rem;
      line-height: 2rem;
      color: #303030;

      .icon-brand-in {
        font-size: 21px;
        line-height: 20px;
      }

      .icon-brand-yt {
        font-size: 26px;
        line-height: 26px;
      }

      .icon-brand-wa {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }

  .items {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    margin: 0 -15px;

    .logo-h {
      margin-bottom: 30px;
      display: block;

      img {
        max-width: 140px;
      }
    }

    .item {
      padding: 0 15px;

      &:nth-child(2) {
        flex: 1;
        text-align: center;
        font-size: 14px;
        line-height: 20.343px;

        p {
          margin-bottom: 0;

          a {
            text-decoration: underline;
            color: #303030;
          }
        }
      }
    }

    .btn-h {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;

      .link {
        color: #1A1A1A;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        text-decoration: none;
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }

        em {
          display: inline-block;
          padding: 0 10px;

          &:before {
            color: #1A1A1A;
          }
        }
      }

      .button {
        font-size: 12px;
        line-height: 23px;
        min-width: 96px;
        padding: 4px 12px;
        min-height: 28px;
        border-radius: 5px;

        &:hover {
          background: #289D3B;
          color: var(--white-color);
        }

        em {
          display: inline-block;
          padding: 0 5px;
          font-size: 9px;

          &:before {
            color: var(--white-color);
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .items {
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .item {
        text-align: left !important;

        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          justify-content: center;
        }
      }

      .logo-h {
        display: none;
      }
    }
  }
}

.color-red {
  color: #FF001F;
}


.rtl-page {
  .questions__holder .form .button:after {
    transform: rotate(180deg);
  }

  .section71 .carousel .items .item:last-of-type {
    right: auto;
    left: 85px;
  }

  .section71:before {
    left: auto;
    right: 0;
    transform: scaleX(-1)
  }


  .section71 .carousel .slider-nav .nav-slide:before {
    content: "\e92f";
    right: auto;
    left: 30px;
  }

  .section71 .carousel .slider-nav .slick-slide:last-of-type .nav-slide:before {
    content: "\e92e";
    right: 30px;
    left: auto;
  }

  .section24 .items .img-h {
    left: auto;
    right: -29rem;
  }

  .section18 .items {
    justify-content: flex-start;
  }

  .section25 .items .img-h {
    left: auto;
    right: 36rem;
  }

  .section26 .ul-list li strong:after {
    left: auto;
    right: 0;
  }

  .section26 .ul-list li {
    padding-left: 0;
    padding-right: 115px;

    &:before {
      left: auto;
      right: 0;
    }

    &:after {
      left: auto;
      right: 25px;
    }
  }


  .section19 .items {
    justify-content: flex-end;
  }

  .section20 .items .img-h img {
    transform: scaleX(-1);
  }


  .section21 .items .img-h {
    right: auto;
    left: 19rem;
  }

  .section15 .items {
    justify-content: flex-end;
  }

  .section14 .status .status-child {
    padding-left: 0;
    padding-right: 25px;
  }

  .section14 .status .status-child.comp:after, .section14 .status .status-child.prog:after {
    left: auto;
    right: 7px;
  }

  .section14 .items .item {
    border-radius: 90px 160.687px 0px 90px;
  }

  .section14 .items .item.comp:after, .section14 .items .item.prog:after {
    right: auto;
    left: 7px;
  }

  .section13 .items {
    justify-content: flex-start;
  }

  ol, ul {
    padding-left: 0;
    padding-right: 2rem;
  }

  .section11 .items {
    justify-content: flex-end;
  }

  .section11 .items .txt-h ul li {
    padding-left: 0;
    padding-right: 7.3rem;
  }

  .section11 .items .txt-h ul li:before {
    left: auto;
    right: 0;
  }


  .payment .items {
    justify-content: flex-start;
  }

  .payment .items .img-h picture {
    display: flex;
    justify-content: flex-end;
  }

  .section25 {
    transform: scaleX(-1);
  }

  .section25 .items {
    transform: scaleX(-1);
  }

  .section25 .items ul li:after {
    left: auto;
    right: 0;
  }

  .icon-arrSliderR1:before {
    content: "\e922";
  }

  .contactus__page {
    .section12.gradient:before {
      left: auto;
      right: 0;
      transform: scaleX(1);
    }
  }

  .holder__faq:before {
    left: auto;
    right: 0;
    transform: scaleX(-1);
  }

  .section39 .section-inner:before {
    left: 50%;
    margin-left: -670px;
    right: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 1200px) {
    .section24 .items .img-h {
      right: -45rem;
    }

    .section11 .items .img-h {
      right: -45rem;
    }
    .section39 .section-inner:before {
      left: 50%;
      margin-left: -540px;
      right: auto;
      margin-right: auto;
    }
  }


  @media only screen and (max-width: 900px) {
    .accordion__parent__faq .link-on:after {
      left: auto;
      right: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .section70 .slick-prev {
      left: 30%;
    }
    .section39 .section-inner:before {
      left: 50%;
      margin-left: -485px;
      right: auto;
      margin-right: auto;
    }
  }

  @media only screen and (max-width: 480px) {
    .section71 .carousel .slider-nav .nav-slide:before {
      content: "\e92e";
      right: 30px;
      left: auto;
    }

    .section71 .carousel .slider-nav .slick-slide:last-of-type .nav-slide:before {
      content: "\e92f";
    }

    .section24 .items .img-h picture img {
      max-width: none;
    }
    .section26 .ul-list li {
      padding-left: 0;
      padding-right: 90px;
    }
    .section26 .ul-list li:after {
      right: 22px;
    }
    .section14 .items .item.comp:after, .section14 .items .item.prog:after {
      left: 10px;
    }
    .section11 .items .txt-h ul li {
      padding-right: 4.4rem;
    }
    .section11 .items .txt-h ul li:before {
      right: 15px;
    }
    .accordion__parent__faq .link-on:before {
      right: auto;
      left: 4px;
    }
    .accordion .accordion-item .accordion-header .accordion-button {
      padding: 0.5rem 1.5rem 0.5rem 5rem;
    }
  }
}
body {
  min-width: 32rem;
}